import { Injectable } from '@angular/core';
import { GetClientesService } from '../services/get-clientes.service';
import { clientes_informacion } from '../interfaces/clientes';
import { PostClientesService } from '../services/post-clientes.service';
import { ModalesFuncionesService } from './modales-funciones.service';
import { ReplaySubject } from 'rxjs';
import { PutClientesService } from '../services/put-clientes.service';

@Injectable({
  providedIn: 'root'
})


export class ClientesFuncionesService {

  url  = new ReplaySubject<any>(1)

  informacion_del_cliente:any 
  informacion_de_visor:any = []

  constructor(private getClientesService:GetClientesService,
              private postClientesService:PostClientesService,
              private modalesFuncionesService:ModalesFuncionesService,
              private putClientesService:PutClientesService) { }

  funcion_consultar_cliente(cedula,tipo_documento,origen){
      return new Promise((resolve,reject) => {
      //borra el cero para limpiar los datos en el frontend
      let cedula_variable = Array.from(cedula)
      if(Array.from(cedula)[0] == 0){
        cedula_variable.shift()
      }
     cedula = cedula_variable.join('')

      this.getClientesService.Consultar_Cliente(cedula,origen)
      .subscribe(cliente_informacion => {
        this.funcion_informacion_del_cliente(cliente_informacion,tipo_documento)
        .then((informacion_del_cliente:any) => {
          this.informacion_del_cliente = informacion_del_cliente
          resolve('terminado')
        })

      }, err => {
        this.modalesFuncionesService.funcion_modal_global('¡Inconveniente en la consulta!','No te frenes, pronto estaremos  en contacto contigo','','ok entiendo')
        .then(res=> {
          reject('error')
        })
      })

    })

  }

  funcion_consultar_modo_E(ev,origen){
    //console.log("en modo E")
    return new Promise((resolve,reject) => {

      let info_cliente:clientes_informacion;

      this.putClientesService.funcion_consultar_cliente_modo_e(ev.cedula,ev,origen)
      .subscribe(cliente => {

        this.funcion_informacion_del_cliente(cliente)
        .then((info:any) => {

          if(!cliente.success){

            if(cliente.data == '' ){
              this.modalesFuncionesService.funcion_modal_global('¡Inconveniente en la consulta!',cliente.message,'')
            }else{
              this.modalesFuncionesService.funcion_modal_global('¡Inconveniente en la consulta!','No te frenes, pronto estaremos  en contacto contigo','','ok entiendo')
              .then(res => {

                //ev.variable = 'ROJO'

                //info_cliente = ev

                /*this.funcion_guardar_la_informacion_del_cliente_en_el_storage(info_cliente)
                .then(res => {
                  resolve('ok');
                })*/

                resolve('error')
              })
            }
          }else{

            info_cliente = info

            this.funcion_guardar_la_informacion_del_cliente_en_el_storage(info_cliente)
            .then(res => {
              resolve('ok');
            })
          }
        })
      },err => {
        this.modalesFuncionesService.funcion_modal_global('¡Inconveniente en la consulta!','No te frenes, pronto estaremos  en contacto contigo','','ok entiendo')
        .then(res=> {
          reject('error')
        })
      })

    })

  }

  funcion_aplicar_motor_beneficios(info){
    return new Promise((resolve,reject) => {
      this.postClientesService.funcion_aplicar_motor_beneficios(info).subscribe(infoMotor => {
        resolve(infoMotor)
      },err => {
        reject('error')
      })
    })
  }

  funcion_guardar_la_informacion_del_cliente_en_el_storage(info_cliente){
    return new Promise((resolve,reject) => {
      localStorage.setItem("cliente", JSON.stringify(info_cliente))
      resolve('ok')
    })
  }

  funcion_informacion_del_cliente(cliente,tipo_documento?){

    return new Promise((resolve,reject) => {
      let informacion_del_cliente = {
        tipo_documento:        tipo_documento ? tipo_documento : '',
        cedula:         cliente.data.n_ide,
        tipo_doc:        tipo_documento ? tipo_documento : '',
        first_name:     cliente.data.primer_nombre,
        cellphone:      cliente.data.celular,
        email:          cliente.data.correo,
        surname:        cliente.data.primer_apellido,
        occupation:     cliente.data.ocupacion,
        income:         cliente.data.ingresos,
        cupo_max:       cliente.data.cupo,
        cuota_sug:      cliente.data.cuota,
        segmento:       cliente.data.segmento,
        variable:       cliente.data.viable,
        salida:         cliente.data.salida,
        estrategia:     cliente.data.estrategia,
        info:           cliente.data.info,
        activity:       cliente.data.actividad_cargo,
        rtaConsulta2:   cliente.data.rtaConsulta2,
        tienda:         '',
        llamar:         false,
        age_est:        cliente.data.age_est,
        cod_est:        cliente.data.cod_est,
        programa:       localStorage.getItem("origen")
      }

      this.informacion_de_visor = cliente.data

      resolve(informacion_del_cliente)

    })


  }

  funcion_consultar_tipo_de_documento(){
    return new Promise((resolve,reject) => {

      this.postClientesService.funcion_consultar_tipo_de_documento()
      .subscribe(tipo_documento => {

        if(tipo_documento.success){
          tipo_documento.data = tipo_documento.data.filter(res => res.TIPO == 'CC' || res.TIPO == '41')
          .map(docu => { if(docu.TIPO == 'CC') {docu.DES = 'CEDULA DE CIUDADANIA'}; return docu })
          resolve(tipo_documento.data)
        }else{
          this.modalesFuncionesService.funcion_modal_global('¡Lo sentimos!','En estos momentos estamos presentando problemas, porfavor intentar en unos segundos','')
        }
      },err => {
        this.modalesFuncionesService.funcion_modal_global('¡Lo sentimos!','En estos momentos estamos presentando problemas con nuestros servicios, porfavor intentar en unos segundos','')
      })

    })

  }

  consultar_ocupaciones_del_cliente(){
    let ocupaciones;

    return new Promise((resolve, reject) => {
      this.getClientesService.consultar_ocupaciones().subscribe(res=> {
        ocupaciones = res['data'].map(res => {return {'text':res.DES_OCU,'value':res.COD_OCU}});
        resolve({ocupaciones})
      })
    })

  }

  consultar_tiposocupacion_del_cliente(ocupacion){
    let activity = []

    return new Promise((resolve, reject) => {

      this.getClientesService.consultar_tiposocupacion(ocupacion)
      .subscribe(res=> {
        let respuesta = res['data'].map(res => {activity.push({text:res.DESC_OCU,value:res.TIPO_OCU})});
      });

      this.getClientesService.consultar_cargos(ocupacion)
      .subscribe(res=> {
        let respuesta = res['data'].map(res => {
          activity.push({text:res.DESCRIPCION,value:res.CODIGO})}
          );
      });

      resolve({activity})

    })
  }

  funcion_consultar_tiendas_mas_cercanas(){

    return new Promise((resolve,reject) => {

      this.postClientesService.funcion_consultar_tiendas_mas_cercanas()
      .subscribe(tipo_documento => {
        if(tipo_documento.success){
          tipo_documento.data = tipo_documento.data.filter(tipo => tipo.C_AGE !=  94 && tipo.C_AGE !=  96)
          tipo_documento.data.filter(tipo => tipo.C_AGE ==  93).map(tienda => tienda.NOM = tienda.NOM.replace("II",""));
          resolve(tipo_documento.data)
        }else{
          this.modalesFuncionesService.funcion_modal_global('¡Lo sentimos!','En estos momentos estamos presentando problemas, porfavor intentar en unos segundos','')
        }
      },err => {
        this.modalesFuncionesService.funcion_modal_global('¡Lo sentimos!','En estos momentos estamos presentando problemas con nuestros servicios, porfavor intentar en unos segundos','')
      })

    })

  }



 /* funcion_hubspot(info){
    console.log(info)
    this.postClientesService.funcion_hubspot(info).subscribe();
  }*/


  function_selesforce(info){
    //console.log(info)
    this.postClientesService.function_enviar_salesforce(info).subscribe();
  }

  function_bd(info){
    this.postClientesService.function_enviar_cliente_bd(info).subscribe();
  }



  // Aca se Crearia la Funcion para llamarla desde el ts Objetivo con Su subscride

}
