// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl:'.execute-api.us-east-1.amazonaws.com/api/v1',
  apiUrl_appsprd: 'https://appsprd.mueblesjamar.com.co/CatalogosService/api/',
  api_key_appsprd: 'd47c29cfdf8e2456ac678c51f9e4ddfa8ec577f64e98aa9e863399f6a10210d4',
  api_sales_force: 'https://ihoffgzow6.execute-api.us-east-1.amazonaws.com/api/v1/api_lead_sf',
  actualizar_bd_cliente: 'https://appsprd.mueblesjamar.com.co/WebJamarService/api/webjamar/GuardarClienteSinConsulta',
  api_key: "d47c29cfdf8e2456ac678c51f9e4ddfa8ec577f64e98aa9e863399f6a10210d4",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
