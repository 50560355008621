import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {CarouselModule} from 'primeng/carousel';



import { HeardGlobalComponent } from './components/heards/heard-global/heard-global.component';
import { Variables } from 'src/environments/variables';

import { HttpClientModule } from '@angular/common/http';
import { FooterGlobalComponent } from './components/footer/footer-global/footer-global.component';

@NgModule({
  declarations: [
    AppComponent,
    HeardGlobalComponent,
    FooterGlobalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    RouterModule
    
  ],
  providers: [
    Variables
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
