import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { clientes_informacion } from 'src/app/interfaces/clientes';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesFuncionesService } from 'src/app/services-funciones/clientes-funciones.service';
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-client-page',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientComponent implements OnInit {

  products: any[] = [];
  benefits: any[] = [];
  agencyCode:string;
  agencyName:string;
  clientName:string;
  clientLastname:string;

  responsiveOptions;

  vector_cliente_informacion:any [] = []

  constructor(private router:Router, public clientesFuncionesService:ClientesFuncionesService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    this.consultar_informacion_del_storage()

    // if( this.vector_cliente_informacion['llamar']){
    //   $('#exampleModalCenter').modal('show')
    // }

    if(this.vector_cliente_informacion["tienda"]){
      let agency = this.vector_cliente_informacion["tienda"].split("---")
      this.agencyCode = agency[0];
      this.agencyName = agency[1];
    }

    this.clientesFuncionesService.funcion_aplicar_motor_beneficios(this.clientesFuncionesService.informacion_de_visor).then(res => {
      try {
        if(res != 'error'){
          this.products = res["products"];        
          this.benefits = res["benefits"];
        }
      } catch (error) {
        console.log("Error al cargar los beneficios");
      }
    })

    localStorage.removeItem("cliente")
  }

  consultar_informacion_del_storage(){
    let infoCliente = localStorage.getItem("cliente")
    if( infoCliente ){
      this.vector_cliente_informacion = JSON.parse( infoCliente )      
      this.clientName = this.vector_cliente_informacion["first_name"] ? this.vector_cliente_informacion["first_name"] : "";
      this.clientLastname = this.vector_cliente_informacion["surname"] ? this.vector_cliente_informacion["surname"] : "";
    } 
    else {
      this.router.navigate(['/form-one'])
    }

  }

  funcion_atender_por_wapp(){

    let informacion = this.clientesFuncionesService.informacion_del_cliente
    

    let cupo = this.clientesFuncionesService.informacion_de_visor["cupo"];

    let msg = encodeURIComponent(`Hola, mi nombre es ${informacion['first_name']}, tengo un cupo pre-aprobado de $${cupo} en la tienda ${this.agencyName} y me gustaría ser atendido por este medio.`);
    let url;
    if (typeof window.orientation == 'undefined') { // desktop
      url = 'https://web.whatsapp.com/send?phone=+50766785136&text=' + msg;
    } else { // mobil
      url = 'https://api.whatsapp.com/send?phone=+50766785136&text=' + msg;
    }
    window.open(url);

  }

}
