//@ts-check
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {CarouselModule} from 'primeng/carousel';

import { FormOneComponent } from './pages/form-one/form-one.component'
import { FormTwoComponent } from './pages/form-two/form-two.component';
import { ClientComponent } from './pages/client/client.component';
import { RutasGuard } from './rutas.guard';

const routes: Routes = [
  { path: 'form-one', component: FormOneComponent},
  { path: 'form-two', component: FormTwoComponent },
  { path: 'client', component: ClientComponent },
  { path: '', children: [], pathMatch: 'full', canActivate: [RutasGuard] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    FormsModule,
    BrowserModule,
    CarouselModule
  ],
  exports: [RouterModule],
  declarations: [
    //pages
    FormOneComponent,
    FormTwoComponent,
    ClientComponent, 
  ]
})
export class AppRoutingModule { }
