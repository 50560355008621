<div class="componentes_del_formulario_uno pb-0 pt-0 mt-0 mb-0">
    <div class="form-container">
        <h2 class="mt-4 mb-3">Comencemos con tus <br> <span>datos personales</span></h2>
    </div>

    <form class="container needs-validation form-credijamar" #formulario="ngForm" (ngSubmit)="onSubmit($event)">
        <div class="row justify-content-center container-inputs">
            <div class="form-container col-12 mb-4">
                <p class="fs-4">Selecciona lo que deseas comprar con Credijamar*</p>
                <div class="form-step-1 w-100">
                    <div class="form-group w-100" [class.active]="categorias.salas" (click)="setCategoryTrue('salas')">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/salas.svg" alt="">
                        <div>Salas</div>
                    </div>
                    <div class="form-group w-100" [class.active]="categorias.comedores" (click)="setCategoryTrue('comedores')">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/comedores.svg" alt="">
                        <div>Comedores</div>
                    </div>
                    <div class="form-group w-100" [class.active]="categorias.recamaras" (click)="setCategoryTrue('recamaras')">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/dormitorios.svg" alt="">
                        <div>Recámaras</div>
                    </div>
                    <div class="form-group w-100" [class.active]="categorias.colchones" (click)="setCategoryTrue('colchones')">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/colchones.svg" alt="">
                        <div>Colchones</div>
                    </div>
                    <div class="form-group w-100" [class.active]="categorias.infantil" (click)="setCategoryTrue('infantil')">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/infantil.svg" alt="">
                        <div>Infantil</div>
                    </div>
                    <div class="form-group w-100" [class.active]="categorias.juvenil" (click)="setCategoryTrue('juvenil')">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/juvenil.svg" alt="">
                        <div>Juvenil</div>
                    </div>
                </div>
                <div class="invalid-tooltip d-block w-100"  *ngIf="enviar && !categoriaValida">
                    Por favor, elige 2 categorías antes de continuar.
                </div>
            </div>
             <div class="col-12 contenedor_inputs">
                <!--[(ngModel)]="vector_cliente_informacion.tipo_documento"-->
                <div class="form-group select_class">
                    <label class="texto_del_formulario">Tipo de  documento*</label>
                    <select class="form-control" name="tipo_documento" [disabled]="loading" required
                        [(ngModel)]="vector_cliente_informacion.tipo_documento">
                        <option *ngFor="let tipo_documentos of vector_tipo_de_documento" [value]="tipo_documentos.TIPO">{{tipo_documentos.DES}}</option>
                    </select>
                    <div class="separador"  *ngIf="enviar && !vector_cliente_informacion.tipo_documento">
                        Debes seleccionar una opción
                    </div>
                    <div class="invalid-tooltip d-block"  *ngIf="enviar && !vector_cliente_informacion.tipo_documento">
                        Debes seleccionar una opción
                    </div>
                </div>

            </div>

            <div class="col-12 contenedor_inputs">
                <div class="form-group">
                    <label class="texto_del_formulario">Tu número de documento*</label>
                    <input  type="text" required autocomplete="off" class="form-control is-document"
                        [class.wrong]="enviarDocumento && !vector_cliente_informacion.cedula || enviarDocumento && vector_cliente_informacion.cedula && !documentoValido"
                        name="cedula" [(ngModel)]="vector_cliente_informacion.cedula"
                        on-focusout="funcion_consultar_cliente(vector_cliente_informacion.cedula,vector_cliente_informacion.tipo_documento)"
                        placeholder="Escribe tu número de documento" 
                        (blur)="mostrarInstruccionesDoc=false;enviarDocumento=true;validarDocumento();"
                        (keydown)="handleKeyPress($event)"
                        (focus)="mostrarInstruccionesDoc=true;cedulaAnterior=vector_cliente_informacion.cedula" >
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarDocumento && !vector_cliente_informacion.cedula">
                    Debes escribir tu número de documento.
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarDocumento && vector_cliente_informacion.cedula && !documentoValido">
                    Asegúrate de escribir un documento válido.
                </div>
            </div>
            <div class="row w-100" *ngIf="mostrarInstruccionesDoc" >
                <div class="col-12">
                    <ul class="ml-4 list-item">
                        <li>Si tú documento de identidad empieza con cero (0) no incluirlo</li>
                        <li>Si tú documento de identidad incluye guiones (-) debes ingresarlo</li>
                        <li>Si tu documento de identidad incluye Letras, debes incluirlas.</li>
                    </ul>
                </div>
            </div>
            <div class="col-12 contenedor_inputs">

                <div class="form-group">
                    <label class="texto_del_formulario">Primer nombre*</label>
                    <input type="text" required autocomplete="off" class="form-control" name="nombre"
                        [class.wrong]="enviarNombre && !vector_cliente_informacion.first_name"
                        [class.wrong]="enviarNombre && vector_cliente_informacion.first_name && (vector_cliente_informacion.first_name.length < 2 || vector_cliente_informacion.first_name.length > 30)"
                        [(ngModel)]="vector_cliente_informacion.first_name" placeholder="Escribe tu primer nombre"
                        (blur)="enviarNombre=true" [disabled]="loading || bloquearNombres">
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarNombre && !vector_cliente_informacion.first_name">
                    Debes escribir tu primer nombre.
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarNombre && vector_cliente_informacion.first_name && (vector_cliente_informacion.first_name.length < 2 || vector_cliente_informacion.first_name.length > 30)">
                    El nombre debe tener entre 2 y 30 letras
                </div>

            </div>

            <div class="col-12 contenedor_inputs">
                <div class="form-group">
                    <label class="texto_del_formulario">Primer apellido*</label>
                    <input type="text" required autocomplete="off" class="form-control" name="apellido"
                        [(ngModel)]="vector_cliente_informacion.surname" placeholder="Escribe tu primer apellido"
                        [class.wrong]="enviarApellido && !vector_cliente_informacion.surname"
                        [class.wrong]="enviarApellido && vector_cliente_informacion.surname && (vector_cliente_informacion.surname.length < 2 || vector_cliente_informacion.surname.length > 30)"
                        (blur)="enviarApellido=true" [disabled]="loading || bloquearNombres" >
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarApellido && !vector_cliente_informacion.surname">
                    Debes escribir tu primer apellido.
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarApellido && vector_cliente_informacion.surname && (vector_cliente_informacion.surname.length < 2 || vector_cliente_informacion.surname.length > 30)">
                    El apellido debe tener entre 2 y 30 letras
                </div>
            </div>

            <div class="col-12 contenedor_inputs">
                <div class="form-group">
                    <label class="texto_del_formulario">Tu número de Whatsapp*</label>
                    <input type="number" required autocomplete="off" class="form-control" name="celular"
                        [class.wrong]="enviarCelular && !vector_cliente_informacion.cellphone || enviarCelular && vector_cliente_informacion.cellphone && !celularValido || enviarCelular && vector_cliente_informacion.cellphone && !celularValidoInicio"
                        [(ngModel)]="vector_cliente_informacion.cellphone" placeholder="Digita tu número de whatsapp"
                        (click)="enableTooltip('tooltipWhatsapp')"
                        (blur)="enviarCelular=true;validarCelular();" [disabled]="loading" >
                        <div class="tooltip-element" [class.active]="tooltips.tooltipWhatsapp">
                            Más adelante nos contactaremos contigo por este WhatsApp.
                        </div>
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarCelular && !vector_cliente_informacion.cellphone">
                    Debes digitar tu número de whatsapp.
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarCelular && vector_cliente_informacion.cellphone && !celularValido">
                    Asegúrate de ingresar un whatsapp válido de 8 dígitos.
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarCelular && vector_cliente_informacion.cellphone && !celularValidoInicio">
                    Por favor, ingresa un celular válido, no debe empezar por 1 o 0
                </div>
            </div>

            <div class="col-12 contenedor_inputs">
                <div class="form-group">
                    <label class="texto_del_formulario">Tu correo electrónico*</label>
                    <input type="email" autocomplete="off" class="form-control" name="email"
                        (click)="enableTooltip('tooltipMail')"
                        [(ngModel)]="vector_cliente_informacion.email" placeholder="Escribe tu email"
                        [class.wrong]="enviarCorreo && !vector_cliente_informacion.email || enviarCorreo && vector_cliente_informacion.email && !correoValido"
                        (blur)="enviarCorreo=true;validarCorreo();" [disabled]="loading" >
                        <div class="tooltip-element" [class.active]="tooltips.tooltipMail">
                            Más adelante nos contactaremos contigo por este correo.
                        </div>
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarCorreo && !vector_cliente_informacion.email">
                    Debes ingresar tu correo electrónico.
                </div>
                <div class="invalid-tooltip d-block"  *ngIf="enviarCorreo && vector_cliente_informacion.email && !correoValido">
                    Asegúrate de escribir un correo electrónico válido.
                </div>
            </div>

            <div id="terminos-apc" class="col-12 contenedor_inputs">
                <div class="terminos-y-condiciones">
                    <div class="checkbox">
                        <span class="checkmark" [class.active]="check" (click)="check = !check" >
                            <img *ngIf="check" src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/checkmark.svg" alt="">
                        </span>
                        <span class="text-decoration-none" (click)="activarElemento()" >Acepto <span class="bold">Términos y condiciones</span> </span>
                    </div>
                    <!-- <input type="checkbox" [checked]="check" (change)="check = !check" />Acepto <span (click)="activarElemento()">Términos y condiciones</span>  -->
                  </div>
                  <div class="invalid-tooltip d-block"  *ngIf="!check && checked">
                    Para continuar, por favor acepta los términos y condiciones.
                </div>
            </div>

            <div class="elemento-emergente-popup-terminos-condiciones" [class.activo]="estaActivo">
                <div class="contenedor-terminos-y-condiciones">
                    <div class="close-terms-agree" (click)="activarElemento()">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_951_9878)">
                            <path d="M16 0.942667L15.0573 0L8 7.05733L0.942667 0L0 0.942667L7.05733 8L0 15.0573L0.942667 16L8 8.94267L15.0573 16L16 15.0573L8.94267 8L16 0.942667Z" fill="#7E7E7D"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_951_9878">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <h4>Términos y Condiciones</h4>
                    <div class="content">
    <h5>Términos y condiciones legales del uso de la aplicación web</h5>
    <p><b>¿Quiénes somos?</b></p>
    <p>El sitio web que estás visitando es propiedad de CREDIJAMAR S.A (CJSA), esta sociedad se encuentra identificada con el 2018546-1-742977 DV 3, su dirección de notificaciones judicial es: Entre Vía España y Vía Brasil, Calle Avenida Belisario Porras, Edificio Muebles Jamar, apartamento 1. Teléfono: 2056501</p>

    <p>Al ingresar, revisar y comprar en este sitio web te comprometes a leer, informarte y cumplir los términos y condiciones de uso, además te obliga a respetar las políticas de privacidad.</p>

    <p>Este documento describe los términos y condiciones generales y las políticas de privacidad aplicables al acceso y uso de los servicios ofrecidos por CREDIJAMAR S.A (CJSA), dentro del sitio <a href="https://tarjetizacion.jamar.com.pa/form-one">Tarjetización</a> y/u otros dominios (urls) relacionados, en donde estos términos y condiciones se encuentren. Cualquier persona que desee acceder y/o suscribirse y/o usar el sitio o los servicios podrá hacerlo sujetándose a los términos y condiciones generales y las políticas de privacidad, junto con todas las demás políticas y principios que rigen CREDIJAMAR S.A (CJSA) y que son incorporados al presente.</p>

    <h5>Capacidad legal</h5>

    <p>Los Servicios sólo están disponibles para personas que tengan capacidad legal para contratar. No podrán utilizar los servicios las personas que no tengan esa capacidad ni los menores de edad. Los actos que éstos realicen en este sitio serán responsabilidad de sus padres, tutores, encargados o curadores, y por tanto se considerarán realizados por éstos en ejercicio de la representación legal con la que cuentan. Quien registre un Usuario como empresa, deberá tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos de este Acuerdo.</p>

    <h5>Alcance de la página web/ aplicación</h5>

    <p>CREDIJAMAR S.A (CJSA), ofrecen, los servicios de pago virtual, consulta de saldos de cuentas abiertas CREDIJAMAR S.A (CJSA), extractos de cuenta, acceso a promociones, beneficios especiales por uso de la app o página web y acceso a paga y gana en <a href="https://tarjetizacion.jamar.com.pa/form-one">https://tarjetizacion.jamar.com.pa/form-one</a>, a sus clientes, mediante el uso de una plataforma digital vía web y/o aplicación, reglamentada a través del presente contrato.</p>

    <h5>Licencia limitada</h5>

    <p>Usted como usuario puede acceder y ver el contenido del Sitio Web desde su computadora o desde cualquier otro aparato y, a menos de que se indique de otra manera en estos Términos y Condiciones o en el Sitio Web, sacar copias o impresiones individuales del contenido del Sitio Web para su uso personal, interno únicamente. El uso del Sitio Web y de los servicios que se ofrecen en o a través del Sitio Web, son sólo para su uso personal, no comercial.</p>
    <h5>Registro y uso del sitio</h5>

    <p>Es obligatorio completar el formulario de registro en todos sus campos con datos válidos para convertirse en Usuario autorizado de la aplicación, acceder a las promociones, y para la adquisición de productos y/o servicios ofrecidos en este sitio. El futuro usuario de la aplicación deberá completar el formulario de registro con su información personal de manera exacta, precisa y verdadera y asume el compromiso de actualizar los Datos Personales conforme resulte necesario.</p>

    <p>CREDIJAMAR S.A (CJSA), podrán utilizar diversos medios para identificar a sus Miembros, pero CREDIJAMAR S.A (CJSA), no se responsabiliza por la certeza de los Datos Personales provistos por sus Usuarios. Los Usuarios garantizan y responden, en cualquier caso, de la exactitud, veracidad, vigencia y autenticidad de los Datos Personales ingresados. Cada miembro sólo podrá ser titular de 1 (una) cuenta en la página web Y/o aplicación, no pudiendo acceder a más de 1 (una) cuenta CREDIJAMAR S.A (CJSA), con distintas direcciones de correo electrónico o falseando, modificando y/o alterando sus datos personales de cualquier manera posible. Si se verificara o sospechara un uso fraudulento y/o malintencionado y/o contrario a estos Términos y Condiciones y/o contrarios a la buena fe, CREDIJAMAR S.A (CJSA), tendrá el derecho inapelable de dar por terminados los créditos, dar de baja las cuentas y hasta de perseguir judicialmente a los infractores.</p>

    <p>El Usuario, una vez registrado, dispondrá de su dirección de email y una clave secreta que le permitirá el acceso personalizado, confidencial y seguro. En caso de poseer estos datos, el Usuario tendrá la posibilidad de cambiar la Clave de acceso, para lo cual deberá sujetarse al procedimiento establecido en el sitio respectivo. El Usuario se obliga a mantener la confidencialidad de su Clave de acceso, asumiendo totalmente la responsabilidad por el mantenimiento de la confidencialidad de su Clave secreta registrada en este sitio web, la cual le permite efectuar compras, solicitar servicios y obtener información de la cuenta. Dicha Clave es de uso personal, y su entrega a terceros no involucra responsabilidad de CREDIJAMAR S.A (CJSA), o de las empresas en caso de utilización indebida, negligente y/o incorrecta.</p>

    <p>El Usuario será responsable por todas las operaciones efectuadas en y desde su Cuenta, pues el acceso a la misma está restringido al ingreso y uso de una Clave secreta, de conocimiento exclusivo del Usuario. El Usuario se compromete a notificar a CREDIJAMAR S.A (CJSA), de forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado de su Cuenta y/o Clave, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión, préstamo o transferencia de la Clave y/o Cuenta bajo ningún título.</p>

    <p>CREDIJAMAR S.A (CJSA), se reserva el derecho de rechazar cualquier solicitud de registro o de cancelar un registro previamente aceptado, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.</p>

    <h5>Uso prohibido</h5>

    <p>Cualquier distribución, publicación o explotación comercial o promocional del Sitio Web, o de cualquiera de los contenidos, códigos, datos o materiales en el Sitio Web, está estrictamente prohibida, a menos de que usted haya recibido el previo permiso expreso por escrito del personal autorizado de Time Inc. o de algún otro poseedor de derechos aplicable. A no ser como está expresamente permitido en el presente contrato, usted no puede descargar, informar, exponer, publicar, copiar, reproducir, distribuir, transmitir, modificar, ejecutar, difundir, transferir, crear trabajos derivados de, vender o de cualquier otra manera explotar cualquiera de los contenidos, códigos, datos o materiales en o disponibles a través del Sitio Web. Usted se obliga además a no alterar, editar, borrar, quitar, o de otra manera cambiar el significado o la apariencia de, o cambiar el propósito de, cualquiera de los contenidos, códigos, datos o materiales en o disponibles a través del Sitio Web, incluyendo, sin limitación, la alteración o retiro de cualquier marca comercial, marca registrada, logo, marca de servicios o cualquier otro contenido de propiedad o notificación de derechos de propiedad. Usted reconoce que no adquiere ningún derecho de propiedad al descargar algún material con derechos de autor de o a través del Sitio Web. Si usted hace otro uso del Sitio Web, o de los contenidos, códigos, datos o materiales que ahí se encuentren o que estén disponibles a través del Sitio Web, a no ser como se ha estipulado anteriormente, usted puede violar las leyes de derechos de autor, así como las leyes estatales aplicables, y puede ser sujeto a responsabilidad legal por dicho uso no autorizado.</p>

    <h5>Modificaciones del acuerdo</h5>
<p>CREDIJAMAR S.A (CJSA), podrá modificar los Términos y Condiciones Generales en cualquier momento, haciendo públicos en el Sitio los términos modificados. Todos los términos modificados entrarán en vigor de manera inmediata posteriormente a su publicación. Dentro de los 5 (cinco) días siguientes a la publicación de las modificaciones introducidas, el Usuario deberá contactarnos si no acepta las mismas; en ese caso quedará disuelto el vínculo contractual y será inhabilitado como Usuario de la página WEB o aplicación. Vencido este plazo, se considerará que el Usuario acepta los nuevos términos y el contrato continuará vinculando a ambas partes.</p>

<h5>Procedimiento para hacer uso de este sitio de internet</h5>

<h5>Información de los usuarios</h5>
<p>Para realizar pagos online será necesario el registro por parte de los usuarios en la página web, para tal efecto se requiere de información personal y confidencial. Esta información será de uso exclusivo de CREDIJAMAR S.A (CJSA), y no será revelada a terceros, salvo la información que requiera la pasarela de pago de CrediCorp Bank para efectos de procesar las transacciones electrónicas de pago.</p>

<h5>Información adicional incluida por los usuarios</h5>
<p>Los datos referidos en estos términos y condiciones tendrán como finalidad validar las órdenes de compra, mejorar la labor de información y comercialización de los productos y servicios prestados por las empresas. Sólo podrán ser entregados a las empresas filiales o relacionadas con de CREDIJAMAR S.A (CJSA) en ningún caso serán traspasados a terceros. Dicha entrega será revocable por el usuario.</p>

<h5>Derechos del usuario</h5>
<p>El usuario gozará de todos los derechos que le reconoce la legislación colombiana sobre Protección al Consumidor y Protección de Datos personales, por tal razón, podrá radicar sus peticiones, quejas, reclamos y sugerencias (PQR) a través de las diferentes herramientas y mecanismos de comunicación establecidos por la página. Igualmente, el usuario también podrá presentarlas de manera presencial nuestras oficinas CREDIJAMAR S.A (CJSA).</p>

<h5>Perfeccionamiento del contrato</h5>
<p>Las productos y precios incluidos en esta página están dirigidos al público en general como personas indeterminadas y por consiguiente será necesaria siempre una confirmación y aceptación de nuestra parte de tu solicitud y/o transacción realizada, momento en el cual se expedirá la correspondiente factura y se entenderá perfeccionando el contrato de venta entre el usuario y CREDIJAMAR S.A (CJSA). El usuario en consecuencia autoriza. En ese orden de ideas, CREDIJAMAR S.A (CJSA), se encontrará obligado efectuar la entrega del producto adquirido a través de este sitio web una vez el usuario reciba un correo electrónico donde se confirma que el pago de los productos fue exitoso, se totaliza el valor de la compra con gastos de transporte y se informa la fecha aproximada de entrega.</p>
<p>PESE A QUE ESTA PÁGINA CUENTA CON CONEXIONES SEGURAS Y CONFIABLES PARA LA REALIZACIÓN DE TRANSACCIONES ELECTRÓNICAS, CREDIJAMAR S.A. SE EXIME DE RESPONSABILIDAD POR CUALQUIER DAÑO QUE SE PUEDA GENERAR ANTE ALGUNA FALLA EN LAS OPERACIONES O COMUNICACIONES DE LAS ENTIDADES FINANCIERAS.</p>

<h5>Promociones – plazo y aceptación de la oferta</h5>
<p>El plazo de validez de la oferta es aquel que coincide con la fecha de vigencia indicada en la promoción debidamente informados al Usuario, o mientras la oferta se mantenga disponible, el menor de éstos plazos. Cuando quiera que en una promoción no se indique una fecha de terminación se entenderá que la actividad se extenderá hasta el agotamiento de los inventarios correspondientes.</p>

<p>Las promociones que se ofrezcan en este Sitio web no son necesariamente las mismas que se ofrezcan en otros canales disponibles para la labor comercial de la empresa, a menos que se señale expresamente en este sitio o en la publicidad que realicen las empresas para cada promoción.</p>

<p>Las promociones de servicios disponibles en el Sitio, mientras aparezcan en él, solo tendrán vigencia y aplicación en éste y no serán aplicables a otros canales de venta utilizados por las empresas. Los servicios ofrecidos en el Sitio están expresados en Pesos colombianos salvo que se manifieste lo contrario.</p>

<p>Las empresas CREDIJAMAR S.A (CJSA) podrán modificar cualquier información contenida en este Sitio, incluyendo las relacionadas con mercaderías, servicios, precios, existencias y condiciones, beneficios, en cualquier momento y sin previo aviso.</p>

<h5>Propiedad intelectual e industrial</h5>
<p>CREDIJAMAR S.A (CJSA), es dueño único y exclusivo, de todos los derechos, título e intereses en y del Sitio Web, de todo el contenido (incluyendo, por ejemplo, audio, fotografías, ilustraciones, gráficos, otros medios visuales, videos, copias, textos, software, títulos, archivos de Onda de choque, etc.), códigos, datos y materiales del mismo, el aspecto y el ambiente, el diseño y la organización del Sitio Web y la compilación de los contenidos, códigos, datos y los materiales en el Sitio Web, incluyendo pero no limitado a, cualesquiera derechos de autor, derechos de marca, derechos de patente, derechos de base de datos, derechos morales, derechos sui generis y otras propiedades intelectuales y derechos patrimoniales del mismo. Su uso del Sitio Web no le otorga propiedad de ninguno de los contenidos, códigos, datos o materiales a los que pueda acceder en o a través del Sitio Web.</p>

<h5>Responsabilidad de credijamar</h5>
<p>CREDIJAMAR S.A (CJSA), hará lo posible dentro de sus capacidades para que la transmisión del Sitio sea interrumpida y libre de errores. Sin embargo, dada la naturaleza de la Internet, dichas condiciones no pueden ser garantizadas. En el mismo sentido, el acceso del Usuario a la Cuenta puede ser ocasionalmente restringido o suspendido con el objeto de efectuar reparaciones, mantenimiento o introducir nuevos Servicios. CREDIJAMAR S.A (CJSA), no será responsable por pérdidas (i) que no hayan sido causadas por el incumplimiento de sus obligaciones; (ii) lucro cesante o pérdidas de oportunidades comerciales; (iii) cualquier daño indirecto.</p>

<h5>Notificaciones</h5>
<p>Cualquier comentario, inquietud o reclamación respecto de los anteriores Términos y Condiciones, la Política de Privacidad, o la ejecución de cualquiera de éstos, deberá ser notificada:</p>

<ul>
    <li>Vía WEB, ingresando a nuestra página de internet <a href="www.credijamar.com.pa">credijamar.com.pa</a> en la sección contáctenos donde el cliente escoge la opción de Servicio al Cliente, Cartera o Información de Venta e ingresa el su PQR, la respuesta será enviada al correo electrónico en un tiempo no mayor a las 48 horas hábiles.</li>
    <li>Chat en línea, ingresando a nuestra página <a href="www.credijamar.com.pa">credijamar.com.pa</a>, este chat es Administrado por la Coordinadora del Contact Center de Servicio al Cliente y atendido por Asesores de SAC Telefónico, o al inbox de la aplicación.</li>
    <li>Redes Sociales: Facebook, Twitter e Instragram, Tiktok</li>
</ul>

<h5>Política de tratamiento de datos personales – aviso de privacidad</h5>
<p>Nuestra Política de Privacidad explica cómo recolectamos, utilizamos y (bajo ciertas condiciones) divulgamos su información personal. Esta Política de Privacidad también explica las medidas que hemos tomado para asegurar su información personal. Por último, esta política de privacidad explica los procedimientos que seguimos frente a la recopilación, uso y divulgación de su información personal.</p>

<h5>Definiciones</h5>
<p>Para efectos de la presente política, las palabras que a continuación se definen tendrán el significado asignado en este capítulo, sea que se escriban o no en mayúsculas, o que se encuentren en plural o singular.</p>

<p>Autorización: Consentimiento previo, expreso e informado del titular para llevar a cabo el Tratamiento de Datos Personales.</p>

<p>Aviso de Privacidad: Documento físico, electrónico o en cualquier otro formato, generado por el Responsable del Tratamiento, que es puesto a disposición del Titular para el Tratamiento de sus Datos Personales, el cual comunica al Titular la información relativa a la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las características del Tratamiento que se pretende dar a los datos personales.</p>

<p>Dato Personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.</p>

<p>Datos Sensibles: Se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos, entre otros.</p>

<p>Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del Responsable del Tratamiento de Datos Personales.</p>

<p>Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos.</p>

<p>Titular: Persona natural cuyos datos personales sean objeto de Tratamiento.</p>

<p>Tratamiento de Datos Personales: Cualquier operación o conjunto de operaciones sobre Datos Personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</p>

Aquí tienes los subtítulos que proporcionaste en formato HTML:

```html
<h5>Normas y criterios de aplicación</h5>
<h6>Principios generales para el tratamiento de datos personales</h6>
<p>En el Tratamiento de Datos Personales se cumplirá con los siguientes principios:</p>
<ul>
    <li>Principio de finalidad: El Tratamiento de Datos Personales debe obedecer a una finalidad legítima que se informará al Titular.</li>
    <li>Principio de libertad: El Tratamiento de Datos Personales sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular.</li>
    <li>Principio de veracidad o calidad: La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible.</li>
    <li>Principio de transparencia: Tratamiento debe garantizarse el derecho del Titular a obtener de CREDIJAMAR S.A (CJSA), en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.</li>
    <li>Principio de acceso y circulación restringida: Los Datos Personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados por aquél.</li>
    <li>Principio de seguridad: La información sujeta a Tratamiento, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</li>
    <li>Principio de confidencialidad: Todas las personas que intervengan en el Tratamiento de Datos Personales están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento.</li>
</ul>

<h6>Objeto</h6>
<p>Con este manual o documento de políticas de tratamiento y protección de datos personales CREDIJAMAR S.A (CJSA), pretende establecer las reglas aplicables, lineamientos legales y corporativos bajo los cuales se realiza el tratamiento y protección de los datos e información personal de los titulares de los mismos, las finalidades, sus derechos, así como los procedimientos establecidos para el ejercicio de tales derechos ante CREDIJAMAR S.A (CJSA),</p>

<p>CREDIJAMAR S.A (CJSA), entiende por protección de datos todas aquellas medidas necesarias que deben tomarse, tanto a nivel físico, técnico, como jurídico para garantizar que la información de nuestros clientes, usuarios, proveedores y/o contratistas, almacenada en bases de datos, esté totalmente segura de cualquier intento de manipulación por parte de personas no autorizadas.</p>

<h6>Tus datos se mantendrán bajo estricta confidencialidad</h6>

<p><a href="www.credijamar.com.pa">www.credijamar.com.pa</a> usa un sistema de seguridad llamado SSL (Secure Socket Layer), que actualmente es el estándar usado por las compañías más importantes del mundo para realizar transacciones electrónicas seguras, lo que significa que toda tu información personal no podrá ser leída, ni capturada por terceros mientras viaja por la red.</p>

<h6>Compromiso con la Seguridad</h6>

<p>En relación a nuestro Sitio <a href="www.credijamar.com.pa">credijamar.com.pa</a> y <a href="https://tarjetizacion.jamar.com.pa/form-one">tarjetizacion.jamar.com.pa</a>, CREDIJAMAR S.A (CJSA) hace esta declaración de seguridad y privacidad en orden a demostrar y comunicar su compromiso con una práctica de negocios de alto nivel ético y dotada de los controles internos apropiados. Además, hace esta declaración para garantizar el compromiso con la protección de los datos personales de los usuarios del Sitio:</p>

<p>Nuestro Sitio está protegido con una amplia variedad de medidas de seguridad, tales como procedimientos de control de cambios, claves y controles de acceso físico. También empleamos otros mecanismos para asegurar que la información y los Datos Personales que usted proporciona no sean extraviados, mal utilizados o modificados inapropiadamente. Esos controles incluyen políticas de confidencialidad y respaldo periódico de bases de datos.</p>

<h6>Finalidad con la que se efectúa la recolección de datos personales y tratamiento de los mismos.</h6>

<p>La autorización para el tratamiento de sus datos personales, permite a CREDIJAMAR S.A (CJSA) recolectar, transferir, almacenar, usar, circular, suprimir, compartir, actualizar y transmitir, para efectos de cumplir con las siguientes finalidades:</p>
<ul>
    <li>Utilización interna en los procesos organizacionales de la compañía que involucren el tratamiento de datos de carácter personal, que a su vez supongan el ejercicio del derecho al habeas data por parte de sus titulares.</li>
    <li>Ejecutar las relaciones contractuales existentes con sus clientes, usuarios, proveedores y trabajadores, incluida el pago de obligaciones contractuales.</li>
    <li>Proveer los servicios requeridos por sus usuarios.</li>
    <li>Informar sobre nuevos servicios y/o sobre cambios en los mismos.</li>
    <li>Evaluar la calidad del servicio.</li>
    <li>Realizar estudios internos sobre hábitos de consumo.</li>
    <li>Enviar al correo físico, electrónico, celular o dispositivo móvil, vía mensajes de texto (SMS y/o MMS) o a través de cualquier otro medio análogo y/o digital de comunicación creado o por crearse, información comercial, publicitaria o promocional sobre los servicios, eventos y/o promociones de tipo comercial o no de estas, con el fin de impulsar, invitar, dirigir, ejecutar, informar y de manera general, llevar a cabo campañas, promociones o concursos de carácter comercial o publicitario, adelantados por CREDIJAMAR S.A (CJSA) y/o por terceras personas.</li>
    <li>Desarrollar el proceso de selección, evaluación, y vinculación laboral.</li>
    <li>Soportar procesos de auditoría intern

a o externa.</li>
    <li>Establecer mecanismos de control, prevención y detección de fraudes de acuerdo con los parámetros de seguridad que CREDIJAMAR S.A (CJSA) y las Entidades Financieras afiliadas al medio de pago consideren.</li>
    <li>Compartir los datos personales recolectados con terceros que tengan la calidad de aliados comerciales y/o de negocio para puedan ofrecer productos y/o servicios que permitan mejorar la oferta de valor destinada a los clientes de CREDIJAMAR S.A (CJSA).</li>
</ul>
<p>El tratamiento de mis datos personales, podrá implicar la recolección, almacenamiento, uso, circulación, supresión, transmisión, transferencia y/o recepción de datos, para la finalidad con que fueron suministrados.</p>

<h6>Tratamiento al cual serán sometidos los datos y finalidad del mismo terceros y enlaces</h6>

<p>CREDIJAMAR S.A (CJSA) podrá transferir y/o transmitir los datos personales sujetos a tratamiento a compañías que hagan parte de su grupo empresarial, esto es, a compañías matrices, filiales o subsidiarias.</p>
<p>También podemos proporcionar su información a nuestros agentes y subcontratistas para que nos ayuden con cualquiera de las funciones mencionadas en nuestra Política de Privacidad. Por ejemplo, podemos utilizar a terceros para que nos ayuden con la entrega de promoción de productos, recaudar sus pagos, enviar productos o tercerizar nuestros sistemas de servicio al cliente.</p>
<p>Podemos intercambiar información con terceros a efectos de protección contra el fraude y la reducción de riesgo de crédito.</p>
<p>Podemos transferir nuestras bases de datos que contienen su información personal si vendemos nuestro negocio o parte de este.</p>
<p>A su vez podrá compartir los datos personales recolectados, con terceros que tengan la calidad de aliados comerciales y/o de negocio para que puedan ofrecer productos y/o servicios que permitan mejorar la oferta de valor destinada a los clientes de CREDIJAMAR S.A (CJSA)</p>
<p>Al margen de lo establecido en la presente Política de Privacidad, no podremos vender o divulgar sus datos personales a terceros sin obtener su consentimiento previo, a menos que sea necesario para los fines establecidos en esta Política de Privacidad o estemos obligados a hacerlo por ley.</p>
<p>Tenga en cuenta que no somos responsables de las prácticas de privacidad o contenido de dichos terceros u otros sitios, ni por cualquier tercero a quien se transfieran sus datos de acuerdo con nuestra Política de Privacidad.</p>

<h6>Autorizaciones</h6>

<p>El Tratamiento de Datos Personales realizados por CREDIJAMAR S.A (CJSA) requiere del consentimiento libre, previo, expreso e informado del Titular de dichos datos.</p>
<p>CREDIJAMAR S.A (CJSA) en su condición de Responsable del Tratamiento de Datos Personales, ha dispuesto de los mecanismos necesarios para obtener la autorización de los titulares garantizando en todo caso que sea posible verificar el otorgamiento de dicha autorización.</p>
<p>La autorización se entiende otorgada a favor de CREDIJAMAR S.A (CJSA) desde el primer uso de la página Web. En cualquier caso la autorización debe ser dada por el Titular y en ésta se debe poder verificar que éste conoce y acepta que CREDIJAMAR S.A (CJSA) recogerá y utilizará la información para los fines que para el efecto se le dé a conocer de manera previa al otorgamiento de la autorización.</p>
<p>En virtud de lo anterior, la autorización solicitada deberá incluir:</p>
<ul>
    <li>a) Responsable del Tratamiento y qué datos se recopilan;</li>
    <li>b) La finalidad del tratamiento de los datos;</li>
    <li>c) Los derechos de acceso, corrección, actualización o supresión de los datos personales suministrados por el titular y,</li>
    <li>d) Sí se recopilan Datos Sensibles.</li>
    <li>e) Adicionalmente se le informará al Titular (i) que no se encuentra obligado a autorizar el tratamiento, y (ii) que es facultativo entregar este tipo de información.</li>
</ul>

<h6>Procedimiento para el ejercicio de los derechos de conocer, actualizar, rectificar y suprimir información y revocar la autorización</h6>

<p>Los Titulares de los Datos Personales podrán en cualquier momento solicitarle al Responsable del Tratamiento qué información de ellos se conserva, así como solicitar la actualización ratificación o supresión de dicha información, usando los medios descritos en el numerar 7 de la presente política.</p>
<p>Adicionalmente podrán revocar la autorización otorgada.</p>
<p>Con dicho fin, el Titular de la información a través de los diferentes medios predeterminados por CREDIJAMAR S.A (CJSA) en el numeral 7, hará el reclamo indicando su número de identificación, los datos de contacto y aportando la documentación pertinente que pretenda hacer valer.</p>
<p>Sí CREDIJAMAR S.A (CJSA) estiman que para el análisis de la solicitud requiere mayor información de parte del Titular, procederá a comunicar tal situación.</p>
<p>Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo.</p>
<p>El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atenderlo dentro de dicho término se informará al interesado antes del vencimiento del referido plazo los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>

<h6>Derechos y deberes de los titulares</h6>

<p>El Titular de los Datos Personales tendrá los siguientes derechos:</p>
<ul>
    <li>a)Conocer, actualizar y rectificar los Datos Personales.</li>
    <li>b)Solicitar pruebas de la autorización otorgada CREDIJAMAR S.A (CJSA)</li>
    <li>c)Ser informado por CREDIJAMAR S.A (CJSA) previa solicitud, respecto del uso que le ha dado a sus Datos Personales.</li>
    <li>d)Presentar consultas ante el Responsable o Encargado del Tratamiento, conforme a lo establecido en el numeral 3 de la presente política, e interponer quejas ante la Superintendencia de Industria y Comercio.</li>
</ul>
<p>El Titular de los Datos Personales tendrá el deber de mantener actualizada su información y garantizar, en todo momento, la veracidad de la misma. CREDIJAMAR S.A (CJSA), no se hará responsable, en ningún caso, por cualquier tipo de responsabilidad derivada por la inexactitud de la información suministrada por el cliente.</p>

<h6>Medidas de seguridad</h6>

<p>CREDIJAMAR S.A (CJSA) adoptará las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. Dichas medidas responderán a los requerimientos mínimos hechos por la legislación vigente y periódicamente se evaluará su efectividad.</p>

<h6>Revocatoria de la autorización</h6>

<p>Los titulares de los datos personales pueden en cualquier momento revocar la autorización otorgada a  CREDIJAMAR S.A (CJSA) para el tratamiento de sus datos personales o solicitar la supresión de los mismos, siempre y cuando no lo impida una disposición legal o contractual.</p>
<p>CREDIJAMAR S.A (CJSA) Establecerá mecanismos sencillos y gratuitos que permitan al titular revocar su autorización o solicitar la supresión sus datos personales, al menos por el mismo medio por el que lo otorgó.</p>
<p>Para lo anterior, deberá tenerse en cuenta que la revocatoria del consentimiento puede expresarse, por una parte, de manera total en relación con las finalidades autorizadas, y por lo tanto CREDIJAMAR S.A (CJSA) deberá cesar cualquier actividad de tratamiento de los datos; y por la otra de manera parcial en relación con ciertos tipos de tratamiento, en cuyo caso serán estos sobre los que cesarán las actividades de tratamiento, como para fines publicitarios, entre otros.</p>
<p>En este último caso, CREDIJAMAR S.A (CJSA). podrá continuar tratando los datos personales para aquellos fines en relación con los cuales el titular no hubiera revocado su consentimiento.</p>

<h5>Responsable y contacto</h5>

<p>CREDIJAMAR S.A (CJSA) estará encargada de la recolección y el Tratamiento de Datos Personales, la Autorización y los registros almacenados, en todos los casos, impidiendo que se deterioren, pierdan, alteren o se usen sin autorización y conservarlos con la debida seguridad.</p>

<p>El área de servicio al cliente está a cargo de atender las peticiones, quejas y consultas de los titulares de los datos y los Titulares de la Información pueden ejercer sus derechos de conocer, actualizar, rectificar y suprimir sus Datos Personales, enviando comunicaciones a:</p>

<ul>
  <li>Oficinas de Servicio al Cliente.</li>
  <li>Vía España – Edificio Muebles Jamar – Ciudad de Panamá, Panamá.</li>
</ul>

<h5>Líneas de Atención al Cliente</h5>

<p>205-6500</p>

<ul>
  <li>Vía WEB, ingresando a nuestra página de internet <a href="http://www.mueblesjamar.com.co">mueblesjamar.com.co</a> en la sección contáctenos donde el cliente escoge la opción de Servicio al Cliente, Cartera o Información de Venta e ingresa su PQR, la respuesta será enviada al correo electrónico en un tiempo no mayor a las 48 horas hábiles.</li>
  <li>Chat en línea, ingresando a nuestra página <a href="http://www.credijamar.com.pa">credijamar.com.pa</a>, este chat es administrado por la Coordinadora del Contact Center de Servicio al Cliente y atendido por Asesores de SAC Telefónico, o al inbox de la aplicación.</li>
  <li>Redes Sociales: Facebook, Twitter e Instagram. TikTok.</li>
</ul>

<h5>Entrada en vigencia, modificación y periodo de vigencia de las bases de datos</h5>

<p>CREDIJAMAR S.A (CJSA) solo podrá recolectar, almacenar, usar o circular los datos personales durante el tiempo que sea razonable y necesario, de acuerdo con las finalidades que justificaron el tratamiento, atendiendo a las disposiciones aplicables a la materia de que se trate y a los aspectos administrativos, contables, fiscales, jurídicos e históricos de la información. Una vez cumplida la o las finalidades del tratamiento y sin perjuicio de normas legales que dispongan lo contrario, procederá a la supresión de los datos personales en su posesión. No obstante lo anterior, los datos personales deberán ser conservados cuando así se requiera para el cumplimiento de una obligación legal o contractual.</p>

<h5>Restricciones en el uso de esta política</h5>

<p>Esta política de protección de datos personales es para uso exclusivo de CREDIJAMAR S.A (CJSA), por tanto, está prohibida su copia, reproducción, distribución, cesión, publicación y/o traducción, por razones de seguridad y en atención al respeto de la propiedad intelectual, como creación amparada por la legislación nacional e internacional.</p>

<h5>Autorización de consulta de centrales de riesgo y datos personales del titular de la información</h5>

<p><strong>Autorizaciones y declaraciones:</strong></p>

<p>Manifiesto de forma libre y espontánea que hemos entendido de forma clara y expresa la información brindada por la Sociedad CREDIJAMAR S.A (CJSA), sociedad legalmente constituida, con domicilio en la ciudad de Panamá identificada con el 2018546-1-742977 DV 3, ubicada en: Entre Vía España y Vía Brasil, Calle Avenida Belisario Porras, Edificio Muebles Jamar, apartamento 1, con dirección electrónica: <a href="http://www.credijamar.com.pa">http://www.credijamar.com.pa</a>, la cual es la responsable del tratamiento de los datos personales obtenidos a través de las relaciones comerciales con clientes y usuarios de sus productos. Por ello, consiento (consentimos) y autorizo (autorizamos) de manera previa, expresa e inequívoca que mis (nuestros) datos personales sean tratados (recolectados, almacenados, usados, compartidos, procesados, transmitidos, transferidos, suprimidos o actualizados,) para el cumplimiento de las siguientes finalidades:</p>

<ul>
  <li>Prestar sus servicios de acuerdo con las necesidades particulares de los clientes de CREDIJAMAR S.A (CJSA) con el fin de cumplir los contratos celebrados, incluyendo pero sin limitarse a:</li>
</ul>

<ol>
    <li>Tramitar las solicitudes de los consumidores finales, deudores, contrapartes contractuales y/o proveedores.</li>
    <li>Negociar y celebrar contratos, y ejecutar los mismos (incluyendo envío de correspondencia).</li>
    <li>Controlar el cumplimiento de requisitos relacionados con el Sistema de Seguridad Social Integral.</li>
    <li>Elaborar estudios técnico actuariales, encuestas, análisis de tendencias de mercado y en general cualquier estudio técnico o de campo relacionado con el sector de comercial o la prestación de productos o servicios ofrecidos por CREDIJAMAR S.A (CJSA).</li>
    <li>Enviar ofertas de productos o servicios o comunicaciones comerciales de cualquier clase relacionadas con los mismos, a través de cualquier medio de comunicación, tales como:
      <ul>
        <li>Envío de mensajes de texto.</li>
        <li>Correo físico y/o electrónico.</li>
        <li>Mensajes a través de WhatsApp u otras redes sociales.</li>
        <li>Llamadas telefónicas o cualquier otro medio que la tecnología y la ley permitan.</li>
      </ul>
      Incluyendo, pero sin limitarse a ofertas de productos y servicios propios o de Compañías con las que CREDIJAMAR S.A (CJSA) tenga convenio.
    </li>
    <li>Autorizo el tratamiento de datos sensibles para cumplir las finalidades descritas, así como para elaboración de score crediticios, herramientas de validación de ingresos, herramientas predictivas de ingresos, herramientas para evitar el fraude, herramientas de validación de identidad, herramientas de georreferenciación y localización, verificación de datos biométricos sensibles como lo son huellas dactilares, fotografías, video grabaciones y en general adelantar una adecuada gestión del riesgo crediticio, compararla, contrastarla y complementarla con la información financiera, comercial, crediticia, de servicios y proveniente de terceros países de APC Intelidat Panamá.</li>
    <li>Consultar, reportar, obtener, actualizar y/o divulgar a centrales de riesgo crediticio u operadores de bancos de datos de información financiera, crediticia, comercial, de servicios y la proveniente de terceros países o entidades similares cualquier información sobre el nacimiento, la modificación, el cumplimiento o incumplimiento y/o la extinción de las obligaciones que llegue a contraer el Titular de la información con CREDIJAMAR S.A (CJSA) con el fin de que estas centrales u operadores y las entidades a ellas afiliadas consulten, analicen y utilicen esta información para sus propósitos legales o contractuales, incluyendo la generación de perfiles individuales y colectivos de comportamiento crediticio y de otra índole, la realización de estudios y actividades comerciales.</li>
    <li>Crear bases de datos de acuerdo a las características y perfiles de los titulares de Datos Personales, todo de acuerdo con lo dispuesto en la ley.</li>
    <li>Realizar actividades relacionadas con la facturación, recaudo, cobro persuasivo jurídico o pre jurídico de acuerdo a lo dispuesto por la ley.</li>
    <li>Solicitar información sobre las relaciones comerciales que tengo (tengamos) con cualquier entidad, incluso financiera. La permanencia de la información que reflejé un eventual incumplimiento dependerá del momento que se efectúe el pago y de la manera como se tramiten y terminen los procesos de cobro. Autorizamos expresamente que las anteriores finalidades y/o tratamientos puedan ser realizadas directamente por CREDIJAMAR S.A (CJSA) o a través de terceros encargados o quien llegare a representar sus derechos o a quien en un futuro se hayan cedido sus derechos u ostente la misma posición contractual. Así mismo, se concede la facultad de destruir la documentación soporte presentada para el estudio del crédito y/o actualizaciones, en caso de que mi (nuestra) solicitud sea negada, o no se realice la transacción, o cuando las obligaciones se extingan.</li>
    <li>Declaro que: la entrega de Datos Personales de carácter sensible (huellas dactilares, grabaciones en cámaras de seguridad, fotografías, entre otros) para el cumplimiento de las finalidades con las que guarde relación, es facultativa, y que he sido informado sobre los derechos a conocer, actualizar y rectificar los Datos Personales.</li>
  </ol>

  <h5>Captura de información de usuarios al usar el sitio</h5>
<p>Durante tu visita a nuestro Sitio, éste podrá capturar información referente a actividades específicas de un usuario particular generándose reportes que nos permiten ver la actividad consolidada por usuario. Usamos "cookies" para seguir tu "carro de compras" y usamos "cookies" para enviarte información específica referida a tus intereses.</p>

<h5>Links con otros sitios</h5>
<p>Este Sitio puede contener "links" a otros sitios. CREDIJAMAR S.A (CJSA) no es responsable de las prácticas de seguridad o privacidad, o el contenido de esos sitios. Asimismo, CREDIJAMAR S.A (CJSA) no avala ningún producto o servicio ofrecido en dichos sitios. De igual forma CREDIJAMAR S.A (CJSA) se rige por Política de protección de datos personales, por lo tanto, no aplican políticas de sitios que derivan tráfico hacia www.credijamar.com.pa</p>

<h5>Mayor información</h5>
<p>La presente Política rige a partir de su publicación y se entiende vigente durante todo el término de la relación existente entre usted y CREDIJAMAR S.A (CJSA).</p>

  




                    </div>
                    <div class="terms-agree" (click)="activarElemento()">Aceptar</div>
                </div>
            </div>

            <div class="col-12 contenedor_btn">
                <!--[disabled]="!validarFormulario()"-->
                <button type="button" class="btn btn-lg btn-block btn-step-1 mt-4"  [ngClass]="!validarFormulario() ? 'falta':'completado'" (click)="ir_al_formulario_dos()" [disabled]="loading">Continuar <i class="ml-3 fas fa-arrow-right"></i></button>
            </div>

        </div>

    </form>

</div>

    <div class="container-loading" [ngClass]="loading ? 'd-flex':'d-none'">
        <div class="loading-info">
            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/cargando.svg" alt="">
            <p class="main-title-mensaje">Estamos consultando tu información</p>
            <p class="secondary-title-mensaje">¡Gracias por esperar!</p>
        </div>
    </div>
