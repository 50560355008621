// scroll.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  scrollToTop(y: number): void {
    window.scroll({
      top: y,
      left: 0,
      behavior: 'smooth'
    });
  }
}
