import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ClientesFuncionesService } from 'src/app/services-funciones/clientes-funciones.service';
import { clientes_informacion } from 'src/app/interfaces/clientes';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api/selectitem';
import { Variables } from 'src/environments/variables';



@Component({
  selector: 'app-form-two-page',
  templateUrl: './form-two.component.html',
  styleUrls: ['./form-two.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class FormTwoComponent implements OnInit {

  vector_formulario = {
    vector_ocupaciones:[],
    tipos_ocupacion:[],
    ocupacion:'',
    actividad:'',
    vector_tiendas:[]
  }

  enviar:boolean = false
  llamar:boolean = true;
  loading:boolean = false;
  bloquearNombres:boolean = false;
  nuevo:boolean = false;
  conocidoConActividad:boolean = true;

  vector_cliente_informacion:any = []
  vector_tipo_de_documento: any [] = []


  constructor(private clientesFuncionesService:ClientesFuncionesService,
              private router:Router,
              private variables:Variables,
              private activatedRoute: ActivatedRoute) {
              this.funcion_consultar_tipo_de_documento()
  }

  ngOnInit() {
    this.clientesFuncionesService.url.next('form-two')
    this.consultar_informacion_del_storage()
    this.funcion_cargar_ocupaciones()
    this.funcion_consultar_tiendas_mas_cercanas()
  }

  funcion_consultar_tipo_de_documento(){
    this.clientesFuncionesService.funcion_consultar_tipo_de_documento()
      .then((tipo_documento:any) => {
        this.vector_tipo_de_documento = tipo_documento;
      })
  }


  funcion_consultar_tiendas_mas_cercanas(){
    this.clientesFuncionesService.funcion_consultar_tiendas_mas_cercanas()
    .then((tiendas:any) => {
      this.vector_formulario.vector_tiendas = tiendas
    })
  }

  funcion_cargar_ocupaciones(){
    this.clientesFuncionesService.consultar_ocupaciones_del_cliente()
    .then((res:any) => {
      this.vector_formulario.vector_ocupaciones = res.ocupaciones;
    })
  }

  funcion_tiposocupacion_del_cliente(id_ocupacion){
    this.clientesFuncionesService.consultar_tiposocupacion_del_cliente(id_ocupacion)
    .then((res:any) => {
      this.vector_formulario.tipos_ocupacion = res.activity;
    })
  }

  checkActividad(){
    return this.vector_formulario.tipos_ocupacion.length > 0 || this.conocidoConActividad;
  }

  selet_ocupaciones(ev){

    if(ev.target.name == 'ocupaciones'){
      this.funcion_tiposocupacion_del_cliente(ev.target.value)
      this.vector_cliente_informacion['occupation'] = ev.target.value
      this.vector_formulario['actividad'] = "";
    }else{
      this.vector_cliente_informacion['activity'] = ev.target.value
    }

  }

  consultar_informacion_del_storage(){
    //this.vector_cliente_informacion = this.clientesFuncionesService.informacion_del_cliente  this.vector_cliente_informacion["salida"] == "NUEVO" &&
    let infoCliente = localStorage.getItem("cliente")
    if( infoCliente ){
      this.vector_cliente_informacion = JSON.parse( infoCliente )
      this.bloquearNombres = this.vector_cliente_informacion["salida"] == "CONOCIDO" //Cambios
      if(this.vector_cliente_informacion['occupation']){
        this.funcion_tiposocupacion_del_cliente(this.vector_cliente_informacion['occupation'])
      }
      this.vector_formulario.ocupacion = this.vector_cliente_informacion['occupation']
      this.vector_formulario.actividad = this.vector_cliente_informacion['activity']
      if(!this.vector_formulario.actividad){
        this.conocidoConActividad = false;
      }
    }
     else {
      this.router.navigate(['/form-one'])
    }
  }

  seguir(){
    this.enviar = true
    this.pasar_al_formulario_dos()

  }

  atras(){
    this.vector_cliente_informacion['validar'] = true
    localStorage.setItem("cliente", JSON.stringify(this.vector_cliente_informacion))
    this.router.navigate(['/form-one'])
  }

  pasar_al_formulario_dos(){

    if( (this.vector_cliente_informacion['occupation'] || this.bloquearNombres) &&
        (this.vector_cliente_informacion['activity'] || !this.checkActividad() || this.bloquearNombres) &&
        this.vector_cliente_informacion['income'] &&
        this.vector_cliente_informacion['tienda']){

        this.vector_cliente_informacion['llamar'] = this.llamar;

        this.loading = true;

        let origen = localStorage.getItem("origen")

        //this.vector_cliente_informacion["salida"] == "CONOCIDO" || (this.vector_cliente_informacion["salida"] == "NUEVO" && this.vector_cliente_informacion["segmento"] != "SIN SEGMENTO")

        //console.log(this.vector_cliente_informacion["salida"])

        if( this.vector_cliente_informacion["salida"] == "CONOCIDO" ){
          this.llamarHubspot(false);
          this.loading = false;
          localStorage.setItem("cliente", JSON.stringify(this.vector_cliente_informacion))
          this.router.navigate(['/client'])
          this.clientesFuncionesService.url.next('client')
        } else {
          this.clientesFuncionesService.funcion_consultar_modo_E(this.vector_cliente_informacion, origen)
          .then(res => {
            if(res != 'error'){
              this.llamarHubspot(true);
              this.loading = false;
              localStorage.setItem("cliente", JSON.stringify(this.vector_cliente_informacion))
              this.router.navigate(['/client'])
              this.clientesFuncionesService.url.next('client')
            } else {
              this.loading = false;
            }
          })
        }

       }
  }

  llamarHubspot(esModoE:boolean){

    let agency = this.vector_cliente_informacion["tienda"].split("---")
    let agencyName = agency[1];
    let agencyNumero = agency[0];
    let empresa = this.variables.c_emp;

    let ocupacion = this.vector_formulario.vector_ocupaciones.filter(ocupacion => ocupacion.value == this.vector_cliente_informacion['occupation'])[0];
    let actividad = this.vector_formulario.tipos_ocupacion.filter(actividad => actividad.value == this.vector_cliente_informacion['activity'])[0];
    let tipoDoc = this.vector_tipo_de_documento.filter(tipoDoc => tipoDoc.TIPO == this.vector_cliente_informacion['tipo_documento'])[0];

    const formData = new FormData();
    formData.append("email", this.vector_cliente_informacion['email']);
    formData.append("tipodoc", tipoDoc['DES']);
    formData.append("nombre", this.vector_cliente_informacion['first_name']);
    formData.append("apellido", this.vector_cliente_informacion['surname']);
    formData.append("celular", this.vector_cliente_informacion['cellphone'].toString());
    formData.append("identificacion", this.vector_cliente_informacion['cedula']);
    formData.append("ocupacion", ocupacion['text']);
    formData.append("actividad_comercial", actividad ? actividad['text'] : '');
    formData.append("ingresos", this.vector_cliente_informacion['income']);
    formData.append("tienda_cercana", empresa+"_"+agencyNumero);
    formData.append("quiero_que_me_llamen", this.vector_cliente_informacion['llamar'] ? 'SI' : 'NO');
    formData.append("secret", "asjAGR23#sdfjkd..123HSGDTRLQWD389asdj23987dlotr(TKT)");


    if (esModoE) {
      let infoCliente = localStorage.getItem("cliente");
      infoCliente = JSON.parse( infoCliente );
      //console.log(infoCliente)
      formData.append("viabilidad", infoCliente["variable"] ? infoCliente["variable"] : '');
      formData.append("salida", infoCliente["salida"] ? infoCliente["salida"] : '');
      formData.append("cupo_disponible", infoCliente["cupo_max"] ? infoCliente["cupo_max"] : 0);
      formData.append("estrategia", infoCliente["estrategia"] ? infoCliente["estrategia"] : '');
      formData.append("segmento", infoCliente["segmento"] ? infoCliente["segmento"] : '');
      formData.append("cuota_disponible", infoCliente["cuota_sug"] ? infoCliente["cuota_sug"] : 0);


    } else {
      formData.append("viabilidad", this.vector_cliente_informacion["variable"] ? this.vector_cliente_informacion["variable"] : '');
      formData.append("salida", this.vector_cliente_informacion["salida"] ? this.vector_cliente_informacion["salida"] : '');
      formData.append("cupo_disponible", this.vector_cliente_informacion["cupo_max"] ? this.vector_cliente_informacion["cupo_max"] : 0);
      formData.append("estrategia", this.vector_cliente_informacion["estrategia"] ? this.vector_cliente_informacion["estrategia"] : '');
      formData.append("segmento", this.vector_cliente_informacion["segmento"] ? this.vector_cliente_informacion["segmento"] : '');
      formData.append("cuota_disponible", this.vector_cliente_informacion["cuota_sug"] ? this.vector_cliente_informacion["cuota_sug"] : 0);

    }
    //formData.append("lect_data", this.vector_cliente_informacion["habeas_data"]);


    /*
    Creo el objeto para enviarlo a la funcion segun parametros establecidos
    bsilvera 16-2020
     */

    console.log(localStorage.getItem("origen"))
    let Record = {
        "identificacion": formData.get("identificacion"),
        "tipoIdentificacion": tipoDoc['DES'],
        "apellidos": this.vector_cliente_informacion['surname'],
        "nombre": this.vector_cliente_informacion['first_name'],
        "correoElectronico": formData.get("email"),
        "celular": "507" + formData.get("celular"),
        "whatsapp": "+507" + formData.get("celular"),
        "ocupacion": formData.get("ocupacion"),
        "sector": formData.get("actividad_comercial"),
        "ingresos": formData.get("ingresos"),
        "empresa": this.variables.c_emp,
        "viabilidad": formData.get("viabilidad"),
        "cupoDisponible": formData.get("cupo_disponible"),
        "salida": formData.get("salida"),
        "estrategia": formData.get("estrategia"),
        "aceptoContactoWhatsapp": true,
        "segmento": formData.get("segmento"),
        "idCliente": this.variables.c_emp + "_" + formData.get("identificacion"),
        "agencia" : formData.get("tienda_cercana"),
        "cuotaDisponible": formData.get("cuota_disponible"),
        "origen": localStorage.getItem("origen"),
        "cuentaPersona": "Cuenta Persona",
        "gclid":localStorage.getItem("google_click")
    }

    //this.clientesFuncionesService.funcion_hubspot(formData);
    //Envia el diccionario a la Funcion ---- bsilvera 16-2020
    this.clientesFuncionesService.function_selesforce(Record);
  }

}
