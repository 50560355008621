import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Variables } from 'src/environments/variables';
import {interval, pipe} from 'rxjs';
import {delay} from "rxjs/operators";
import set = Reflect.set;


const apiUrl = environment.apiUrl;
const apiUrl_appsprd = environment.apiUrl_appsprd;
const api_key_appsprd = environment.api_key_appsprd;
const numbers = interval(600000);



@Injectable({
  providedIn: 'root'
})
export class PostClientesService {
  private datos: any;
  private info: {};

  constructor(private http: HttpClient,
              private variables:Variables) {
      /*
        llama la funcion que hace la peticion del token
        bsilvera 16-2020
       */
      //this.function_consultar_respuesta_token()
  }


  funcion_consultar_tipo_de_documento(){
    let info = JSON.stringify([{"variable":"c_emp","operador":"=","valor":"'"+this.variables.c_emp_dos+"'"}])
    return this.http.post<any>(`${this.variables.WEB_LOGIC_APP_ID}${apiUrl}/${this.variables.c_emp_dos}/doc-type/`,info)
  }



  /*
  Hace la peticion a la url de salesforce para obtener el token
  le enviamos los parametros
  bsilvera 16-2020
   */
  /*funcion_post_token(){
    let parametros = new HttpParams()
    parametros = parametros.append("client_id", "3MVG9oNqAtcJCF.EF2CbJvDlzcwaB8eFHbkjZW6EoM25bF_IlY1lVlw1UnK53npFeiAevwl5sovZJCiVHJNV8")
    parametros = parametros.append("client_secret", "4122A90D4FF937BF2AA1A2C3FA443847B2EE27444BFCF9AD88C65C99BA541EB8")
    parametros = parametros.append("username", "integrador@mueblesjamar.com.co")
    parametros = parametros.append("password", "Jamar202006*q7yvxHf082AUb1onTPFiXIHW1")
    parametros = parametros.append("grant_type", "password")
    let token = this.http.post(`${environment.api_token_salesforce}`, "", {params:parametros})
    return token
  }*/

  funcion_actualizar_contacto_bd(info){

  }

  /*
  Hacemos una promesa a la funcion que nos retorna el observable
  y hacemos un subscribe al igual guardamos  el token en el localStorage del cliente

  bsilvera 16-2020
   */
  /*function_consultar_respuesta_token() {
    return new Promise((resolve, reject) => {
      this.funcion_post_token()
        .subscribe(token => {
          resolve(token)
        })
    }).then((token:any)=>{
      localStorage.setItem("access_token", token.access_token)
    })
  }*/
  /*
 Esta Funcion obtiene el diccionario de datos
 lo parsea en json, obtenemos el token de autorizacion de salesforce
 guardado en el storage, creamos los headers y llamamos el token
 por ultimo hacemos el post y le pasamos el diccionario parseado y los headers
  */


  function_enviar_salesforce(info){
    let jsoninfo = JSON.stringify(info);
    return this.http.post(environment.api_sales_force,jsoninfo)
  }

  function_enviar_cliente_bd(info){

    let paramsURL = new URLSearchParams();

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };



    var resultArray = Object.keys(info).map(function(personNamedIndex){
      let person = info[personNamedIndex];
      // do something with person
      return person;
    });

    const body = new HttpParams()
      .set('n_ide', resultArray[0])
      .set('c_emp', resultArray[1])
      .set('tipo_doc', resultArray[2])
      .set('primer_apellido', resultArray[3])
      .set('primer_nombre', resultArray[4])
      .set('celular', resultArray[5])
      .set('ingresos', resultArray[6])
      .set('correo', resultArray[7])
      .set('api_key', resultArray[8])
      .set('ocupacion', resultArray[9])
      .set('actividad_cargo', resultArray[10])
      .set('sector_trabajo', resultArray[11])
      .set('age_est', resultArray[12])
      .set('cod_est', resultArray[13])
    return this.http.post(environment.actualizar_bd_cliente, body.toString(),options)
  };

  funcion_consultar_tiendas_mas_cercanas(){
    let info = JSON.stringify([{"variable":"c_emp","operador":"=","valor":"'"+this.variables.c_emp_dos+"'"},{"variable":"TUBO_CREDITO","operador":"=","valor":"'S'"}])
    return this.http.post<any>(`${this.variables.WEB_LOGIC_APP_ID}${apiUrl}/${this.variables.c_emp_dos}/agencies/`,info)
  }

  funcion_aplicar_motor_beneficios(info){
    return this.http.post<any>(`${this.variables.STEP_IMAGE_APP_ID}${apiUrl}/${this.variables.c_emp_dos}/benefits-engines/apply`,info)
  }

 /* funcion_hubspot(info){
    return this.http.post<any>(`${this.variables.URL_HUBSPOT}`,info)
  }*/



}
