import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesFuncionesService } from 'src/app/services-funciones/clientes-funciones.service';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-heard-global-component',
  templateUrl: './heard-global.component.html',
  styleUrls: ['./heard-global.component.scss']
})
export class HeardGlobalComponent implements OnInit {

  url:any;

  constructor(private clientesFuncionesService: ClientesFuncionesService,
              private router:Router,
              public scrollServicio: ScrollService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.clientesFuncionesService.url.subscribe(res => {
      this.url = res
    })
  }

  funcion_regresar_a_inicio(){
    //if(this.url ==  'client'){
      this.clientesFuncionesService.informacion_del_cliente = []
      this.router.navigate(['/form-one'])
    //}
  }

}
