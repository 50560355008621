import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Variables } from 'src/environments/variables';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PutClientesService {

  constructor(private http: HttpClient,
              private variables:Variables) { }

  funcion_consultar_cliente_modo_e(cedula,informacion:any,origen){
    return this.http.put<any>(`${this.variables.ORDER_APP_ID}${apiUrl}/${this.variables.c_emp}/clients/${cedula}?user=TJWEB&program=${origen}`,informacion);
  }
}
