import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer-global-component',
  templateUrl: './footer-global.component.html',
  styleUrls: ['./footer-global.component.scss']
})
export class FooterGlobalComponent implements OnInit {

  showFooter: boolean = true;

  constructor(private router: Router) { }


  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.checkUrl(event.urlAfterRedirects);
    });
  }

  checkUrl(url: string) {
    const noFooterUrls = ['/form-two'];
    this.showFooter = !noFooterUrls.includes(url);
  }

}
