<div class="d-flex flex-column align-items-center">
    <!-- Header principal -->
    <div class="header-principal">
        <a target="_blank" href="https://jamar.com.pa"><img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/logo-jamar.svg" alt=""></a>
        <span class="circle"></span>
        <a target="_blank" href="https://credijamar.com.pa"><img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/logo-credijamar.svg" alt=""></a>
    </div>
    <!-- Componentes del header que muestra informacion -->
    <div target="_blank" class="contenedor_de_cabecera level-1" *ngIf="url === 'form-one'" (click)="this.scrollServicio.scrollToTop(390)" >
        <img src="https://pub-679e6e52a0524655b51d226e575658f6.r2.dev/banner-tj.svg" alt="">   
    </div>
    <div class="contenedor_de_cabecera level-1" *ngIf="url === 'form-one'" (click)="this.scrollServicio.scrollToTop(390)">
        <img src="https://pub-679e6e52a0524655b51d226e575658f6.r2.dev/valor-tj-banner-v2.svg" alt="">   
    </div>
    <!-- Contenedor del progreso del usuario -->
    <section class="steps-container" [ngClass]="{'d-none': url === 'client'}" >
        <div class="steps" *ngIf="url == 'form-one'">
            <div class="item active">1</div>
            <div class="item">2</div>
            <div class="item">3</div>
            <span class="line"></span>
        </div>
        <div class="steps" *ngIf="url == 'form-two'">
            <div class="item active">
                <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/fi-rs-check.svg" alt="">
            </div>
            <div class="item active">2</div>
            <div class="item">3</div>
            <span class="line"></span>
        </div>
        <div class="steps" *ngIf="url == 'client'">
            <div class="item active">
                <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/fi-rs-check.svg" alt="">
            </div>
            <div class="item active">
                <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/fi-rs-check.svg" alt="">
            </div>
            <div class="item active">3</div>
            <span class="line"></span>
        </div>
        <div class="steps">
            <div class="item-text active">Datos personales</div>
            <div class="item-text">¿A qué te dedicas?</div>
            <div class="item-text">Descubre tu crédito</div>
        </div>
    </section>
</div>