import { Component, OnInit } from '@angular/core';
import { clientes_informacion } from '../../interfaces/clientes';
import { ClientesFuncionesService } from 'src/app/services-funciones/clientes-funciones.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import {Variables} from "../../../environments/variables";
import { Tooltips, Categorias } from 'src/app/interfaces/interfaces';




@Component({
  selector: 'app-form-one-page',
  templateUrl: './form-one.component.html',
  styleUrls: ['./form-one.component.scss']
})

export class FormOneComponent implements OnInit {

  //objetos de almacenamiento global
  vector_cliente_informacion:any = []

  //bloqueantes de los envios
  enviar:boolean = false
  enviarDocumento:boolean = false
  enviarNombre:boolean = false
  enviarApellido:boolean = false
  enviarCelular:boolean = false
  enviarCorreo:boolean = false
  check:boolean = false
  checked:boolean = false

  vector_tipo_de_documento: any [] = []


  loading:boolean = false
  correoValido:boolean = false
  celularValido:boolean = false
  celularValidoInicio:boolean = true
  mostrarInstruccionesDoc:boolean = false
  origen:string;
  googleClick:string;
  googleClick_name="google_click"
  cedulaAnterior:any;
  bloquearNombres:boolean = false;

  documentoValido:boolean = false;
  estaActivo: boolean = false;
  
  categorias: Categorias = {
    salas: false, 
    comedores: false,
    recamaras: false,
    colchones: false,
    infantil: false,
    juvenil: false
  }

  categoriaValida:boolean = false;

  tooltips: Tooltips = {
    tooltipWhatsapp: false,
    tooltipMail: false,
  };

  //funcion que valida los elementos del tooltip después de 300ms muestra el mensaje
  enableTooltip(element){
    this.tooltips[element] = true;
    setTimeout(() => {
      this.tooltips[element] = false;
    }, 3000);
  }

  //activación de las categorías
  setCategoryTrue(key: string) {
    if (this.categorias.hasOwnProperty(key)) {
      this.categorias[key] = !this.categorias[key];
    } 
  }
  

  //Activar el elemento de check de la funcionalidad de pago
  activarElemento() {
    this.estaActivo = !this.estaActivo;
    this.check = true;
    this.checked = true;
  }

  constructor(private clientesFuncionesService:ClientesFuncionesService,
              private router:Router,
              private activatedRoute: ActivatedRoute,
              private variables:Variables) {
    const params = this.activatedRoute.snapshot.queryParams
    if(params["gclid"]){
      localStorage.setItem(this.googleClick_name,params["gclid"])
    }
    this.activatedRoute.queryParams.subscribe(params => {
        let o = params['origin'];
        this.origen = o ? o : "Aplica_Credito";
        localStorage.setItem("origen", this.origen)
    });
    this.loading = false;
  }

  ngOnInit() {
    this.funcion_consultar_tipo_de_documento() // Al iniciar Trae Todos los Tipos de Documento e la BD
    this.consultar_informacion_del_storage()  // Cosulta el local storage del usuario
    if(!this.vector_cliente_informacion['validar']){
      localStorage.removeItem("cliente")
    }
  }

  //extracción de las sbcategoria
  getSelectedCategories(){
    let selectedCategories = [];
    for (let key in this.categorias) {
      if (this.categorias[key]) {
        selectedCategories.push(key.toUpperCase());
      }
    }
    return selectedCategories.join(', ');
  }

  funcion_seleccionar(ev,variable){
    this.vector_cliente_informacion[variable] = ev.target.value
  }

  funcion_consultar_cliente(cedula,tipo_documento){
    if(cedula && cedula != this.cedulaAnterior){
      this.validarDocumento();
      if(this.documentoValido){
        this.loading = true
        this.bloquearNombres = false
        this.clientesFuncionesService.funcion_consultar_cliente(cedula.toUpperCase(),tipo_documento, this.origen)
        .then((cliente_informacion:any) => {
          this.vector_cliente_informacion = this.clientesFuncionesService.informacion_del_cliente;
          localStorage.setItem("cliente", JSON.stringify(this.vector_cliente_informacion))
          this.loading = false
          // this.bloquearNombres = this.vector_cliente_informacion["salida"] == "CONOCIDO" || (this.vector_cliente_informacion["salida"] == "NUEVO" && this.vector_cliente_informacion["segmento"] != "SIN SEGMENTO")
        })
        .catch(err =>{
          this.loading = false
        })
      }

    }
  }
  
 // Consulta Los tipos de Documentos en BD
  funcion_consultar_tipo_de_documento(){
    this.clientesFuncionesService.funcion_consultar_tipo_de_documento()
    .then((tipo_documento:any) => {
      this.vector_tipo_de_documento = tipo_documento;
    })

    this.clientesFuncionesService.url.next('form-one')
  }


  // Consultar información desde el localstorage
  consultar_informacion_del_storage(){
    let infoCliente = localStorage.getItem("cliente")
    if( infoCliente ){
      this.vector_cliente_informacion = JSON.parse( infoCliente )
      this.bloquearNombres = this.vector_cliente_informacion["salida"] == "CONOCIDO" || (this.vector_cliente_informacion["salida"] == "NUEVO" && this.vector_cliente_informacion["segmento"] != "SIN SEGMENTO")
    } else {
      this.vector_cliente_informacion['tipo_documento'] = "CC"
    }
  }

  validarFormulario(){

    this.validarCorreo();
    this.validarCelular();
    this.categoriaValidacion();

    return this.vector_cliente_informacion['surname'] &&
        this.vector_cliente_informacion['cedula'] &&
        this.vector_cliente_informacion['cellphone'] &&
        this.vector_cliente_informacion['first_name'] &&
        this.vector_cliente_informacion['tipo_documento'] &&
        this.vector_cliente_informacion['email'] &&
        this.correoValido &&
        this.celularValido &&
        this.documentoValido &&
        this.categoriaValida &&
        this.check;
  }

  categoriaValidacion(){
    if (this.categorias.salas || this.categorias.comedores || this.categorias.recamaras || this.categorias.colchones || this.categorias.infantil || this.categorias.juvenil){
      this.categoriaValida = true; 
    } else {
      this.categoriaValida = false;
    }
  }

  validarDocumento(){
    let patt = this.vector_cliente_informacion['tipo_documento'] == "CC" ?
      /^(PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(\d{1,4})-(\d{1,6})$/ :
      /^(?!^0+$)[a-zA-Z0-9]{6,9}$/;
    this.documentoValido = patt.test(this.vector_cliente_informacion['cedula']);
  }

  validarCelular(){
    let pattCel = /^[0-9]{8,8}$/
    this.celularValido = pattCel.test(this.vector_cliente_informacion['cellphone']);

    if(this.celularValido){
      pattCel = /^[2-9]/
      this.celularValidoInicio = pattCel.test(this.vector_cliente_informacion['cellphone']);
    } else {
      this.celularValidoInicio = true;
    }
  }

  validarCorreo(){
    let patt = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-_0-9]+\.)+[a-zA-Z]{2,}))$/
    this.correoValido = patt.test(this.vector_cliente_informacion['email']);
  }

  ir_al_formulario_dos(){
    this.checked = false
    if(!this.check){
      this.checked = true
    }
    this.enviar = true
    this.enviarDocumento = true
    this.enviarNombre = true
    this.enviarApellido = true
    this.enviarCelular = true
    this.enviarCorreo = true
    if(this.validarFormulario()){
      this.pasar_al_formulario_dos()
    } else {
      window.scroll({
        top: 300,
        left: 0,
      }); // Desplazar el scroll al inicio
    }
  }

  pasar_al_formulario_dos(){
    let tipoDoc = this.vector_tipo_de_documento.filter(tipoDoc => tipoDoc.TIPO == this.vector_cliente_informacion['tipo_documento'])[0];
    this.loading = true
    const formData = new FormData();
    formData.append("tipo_de_documento", tipoDoc['DES']);
    formData.append("cod_documento", this.vector_cliente_informacion['tipo_documento']);
    formData.append("identificacion", this.vector_cliente_informacion['cedula']);
    formData.append("primer_nombre", this.vector_cliente_informacion['first_name']);
    formData.append("primer_apellido", this.vector_cliente_informacion['surname']);
    formData.append("celular_1", this.vector_cliente_informacion['cellphone'].toString());
    formData.append("email", this.vector_cliente_informacion['email']);
    formData.append("ingresos", this.vector_cliente_informacion['income'] ? this.vector_cliente_informacion['income'] : 0);
    formData.append("viabilidad", this.vector_cliente_informacion['variable'] ? this.vector_cliente_informacion['variable'] : '');
    formData.append("salida", this.vector_cliente_informacion['salida'] ? this.vector_cliente_informacion['salida'] : '');
    formData.append("cupo_disponible", this.vector_cliente_informacion['cupo_max'] ? this.vector_cliente_informacion['cupo_max'] : 0);
    formData.append("cuota_disponible", this.vector_cliente_informacion['cuota_sug'] ? this.vector_cliente_informacion['cuota_sug'] : 0);
    formData.append("estrategia", this.vector_cliente_informacion['estrategia'] ? this.vector_cliente_informacion['estrategia'] : '');
    formData.append("segmento", this.vector_cliente_informacion['segmento'] ? this.vector_cliente_informacion['segmento'] : '');
    formData.append("secret", "asjAGR23#sdfjkd..123HSGDTRLQWD389asdj23987dlotr(TKT)");
    //console.log("Des Form-" + formData.get('tipo_de_documento')) // Se guarda los datos en Un formdata  para pasar a otro form



    if(this.vector_cliente_informacion['occupation']){
        this.clientesFuncionesService.consultar_ocupaciones_del_cliente()
        .then((res:any) => {
          let ocu = res.ocupaciones.filter(ocu => ocu.value == this.vector_cliente_informacion['occupation'])
          if(ocu && ocu.length > 0){
            formData.append("ocupacion", ocu[0]['text']);
            this.clientesFuncionesService.consultar_tiposocupacion_del_cliente(ocu[0]['value'])
            .then((res2:any) => {
              setTimeout(() => {
                let acti = res2.activity.filter(act =>  act.value == this.vector_cliente_informacion['activity'])
                if (acti && acti.length > 0) {
                  formData.append("actividad_comercial", acti[0]['text']);
                  //this.sendInfoHub(formData);
                  this.sendInfoConocidoSalesForce(formData);
                } else {
                  formData.append("actividad_comercial", '');
                  //this.sendInfoHub(formData);
                  this.sendInfoConocidoSalesForce(formData);
                }
              }, 1000);
            }).catch(error => {
              formData.append("actividad_comercial", '');
              //this.sendInfoHub(formData);
              this.sendInfoConocidoSalesForce(formData);
            })
          } else {
            formData.append("ocupacion", '');
            formData.append("actividad_comercial", '');
            //this.sendInfoHub(formData);
            this.sendInfoConocidoSalesForce(formData);


          }
        }).catch(error => {
          formData.append("ocupacion", '');
          formData.append("actividad_comercial", '');
          //this.sendInfoHub(formData);
          this.sendInfoConocidoSalesForce(formData);


        })
    } else {
      //this.sendInfoHub(formData);
      this.sendInfoConocidoSalesForce(formData);
    }
  }

  /*sendInfoHub(formData){
    this.clientesFuncionesService.funcion_hubspot(formData);
    localStorage.setItem("cliente", JSON.stringify(this.vector_cliente_informacion))
    this.router.navigate(['/form-two'])
    this.clientesFuncionesService.url.next('form-two')
  }*/


  sendInfoConocidoSalesForce(formData){

    const googleClick = localStorage.getItem(this.googleClick_name)

    let Record
    if(googleClick != '' && googleClick != undefined && googleClick != 'undefined'){
      Record = {
        "identificacion": formData.get("identificacion"),
        "tipoIdentificacion": formData.get("tipo_de_documento"),
        "apellidos": formData.get("primer_apellido"),
        "nombre": formData.get("primer_nombre"),
        "correoElectronico": formData.get("email"),
        "celular": "507" + formData.get("celular_1"),
        "whatsapp": "+507" + formData.get("celular_1"),
        "ocupacion": formData.get("ocupacion"),
        "sector": formData.get("actividad_comercial"),
        "ingresos": formData.get("ingresos"),
        "empresa": this.variables.c_emp,
        "origen": this.origen,
        "viabilidad": formData.get("viabilidad"),
        "cupoDisponible": formData.get("cupo_disponible"),
        "salida": formData.get("salida"),
        "estrategia": formData.get("estrategia"),
        "aceptoContactoWhatsapp": true,
        "segmento": formData.get("segmento"),
        "idCliente": this.variables.c_emp + "_" + formData.get("identificacion"),
        "cutoaDisponible": formData.get("cuota_disponible"),
        "cuentaPersona": "Cuenta Persona",
        "gclid": googleClick
      }
    }else{
      Record = {
        "identificacion": formData.get("identificacion"),
        "tipoIdentificacion": formData.get("tipo_de_documento"),
        "apellidos": formData.get("primer_apellido"),
        "nombre": formData.get("primer_nombre"),
        "correoElectronico": formData.get("email"),
        "celular": "507" + formData.get("celular_1"),
        "whatsapp": "+507" + formData.get("celular_1"),
        "ocupacion": formData.get("ocupacion"),
        "sector": formData.get("actividad_comercial"),
        "ingresos": formData.get("ingresos"),
        "empresa": this.variables.c_emp,
        "origen": this.origen,
        "viabilidad": formData.get("viabilidad"),
        "cupoDisponible": formData.get("cupo_disponible"),
        "salida": formData.get("salida"),
        "estrategia": formData.get("estrategia"),
        "aceptoContactoWhatsapp": true,
        "segmento": formData.get("segmento"),
        "idCliente": this.variables.c_emp + "_" + formData.get("identificacion"),
        "cutoaDisponible": formData.get("cuota_disponible"),
        "cuentaPersona": "Cuenta Persona",
        "productoInteres": this.getSelectedCategories()

      }
    }

    let obj = {
      "n_ide": formData.get("identificacion"),
      "c_emp": this.variables.c_emp,
      "tipo_doc": formData.get("cod_documento"),
      "primer_apellido": formData.get("primer_apellido"),
      "primer_nombre": formData.get("primer_nombre"),
      "celular": formData.get("celular_1"),
      "ingresos": formData.get("ingresos"),
      "correo": formData.get("email"),
      "api_key": "d47c29cfdf8e2456ac678c51f9e4ddfa8ec577f64e98aa9e863399f6a10210d4",
      "ocupacion": this.vector_cliente_informacion['occupation'],
      "actividad_cargo":"",
      "sector_trabajo":"",
      "age_est":this.vector_cliente_informacion['age_est'],
      "cod_est":this.vector_cliente_informacion['cod_est']
    }

    if(this.vector_cliente_informacion['salida']=="CONOCIDO"){
      this.clientesFuncionesService.function_selesforce(Record);
      this.clientesFuncionesService.function_bd(obj);// esta funcion envia bd
      localStorage.setItem("cliente", JSON.stringify(this.vector_cliente_informacion))
      this.router.navigate(['/form-two'])
      this.clientesFuncionesService.url.next('form-two')

    }else{
      //this.clientesFuncionesService.function_selesforce(Record);
      localStorage.setItem("cliente", JSON.stringify(this.vector_cliente_informacion))
      this.router.navigate(['/form-two'])
      this.clientesFuncionesService.url.next('form-two')
    }
    //console.log(Record);
  }

  // function para validar que el usuario presione enter
  handleKeyPress(evento: KeyboardEvent){
    if (evento.key === 'Enter') {
      // Aquí puedes llamar a cualquier función o establecer valores como en el blur
      this.mostrarInstruccionesDoc = false;
      this.enviarDocumento = true;
      this.validarDocumento();
    }
  }

  // Function para prevenir el comportamiento por defecto del formulario
  onSubmit(event: Event) {
    event.preventDefault();
  }


}
