import { Injectable } from '@angular/core';

import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class ModalesFuncionesService {

  constructor() { }

  funcion_modal_global(titulo,descripcion,img,btn?,html_res?,error?){

    let htmlRender = `    
    <div class="contenedor-viable">
        <div class="contenido">
            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/gray-illustration.svg" alt="Imagen de atención en caso de error.">
           <p class="text">Ingresa a nuestro chat y un asesor experto te ayudará a terminar tu solicitud.</p>
        </div>
    </div>
    `

    return new Promise((resolve, reject) => {
      Swal.fire({
        imageUrl: img,
        imageAlt: 'Custom image',
        title: `<h4 class="title bold">¡Queremos conocerte más!</h4>`,
        text: `${descripcion}`,
        html: htmlRender,
        confirmButtonColor:'#25D366',
        background:'rgba(255, 255, 255, 0.96)',
        showCloseButton: true,
        showConfirmButton: true,
        heightAuto:false,
        customClass: {
          confirmButton: 'confirm-button-class'
        },
        confirmButtonText: `<img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/whatsapp.svg" alt=""> ¡Chatea con un asesor!`,
      }).then((result) => {
      if (result.value) {
        window.open('https://wa.me/50766779749', '_blank');
      }
      })

    })

  }
}
