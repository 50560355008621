import { Injectable } from "@angular/core";
@Injectable()
export class Variables {
    // // AWS QAS
    // public STEP_IMAGE_APP_ID = 'https://aa9cqxppt8'
    // public SELLER_APP_ID = 'https://s0ldp77duj'
    // public WEB_LOGIC_APP_ID = 'https://pajs0srmnf'
    // public MEC_EXTENSION_APP_ID = 'https://rg8p9mxsfi'
    // public ORDER_APP_ID = 'https://ekzz4zlxde'
    // public ORDER_REQUEST_ID = "https://m21ehgue3l"
    // public VERSION = 'qas'

    // AWS PRD
    public STEP_IMAGE_APP_ID = 'https://6kwvv4d820'
    public SELLER_APP_ID = 'https://sbeuw1vi17'
    public WEB_LOGIC_APP_ID = 'https://ihoffgzow6'
    public MEC_EXTENSION_APP_ID = 'https://3xassuir58'
    public ORDER_APP_ID = 'https://gm36tm91y3'
    public ORDER_REQUEST_ID = "https://xvjhk5jhn4"
    // public VERSION = 'prd'

    // AWS DEV
    // public STEP_IMAGE_APP_ID = 'https://uq707rv585'
    // public SELLER_APP_ID = 'https://bmrhm8ji8k'
    // public WEB_LOGIC_APP_ID = 'https://3hlabd0295'
    // public MEC_EXTENSION_APP_ID = 'https://rethjllv87'
    // public ORDER_APP_ID = 'https://l04wzzd9ra'
    // public ORDER_REQUEST_ID = "https://q6ym7f3983"

    public c_emp = 'JP';
    public c_emp_dos = 'JP';
    public cod_pais = '01';
    /*
    Creo la variable origen para llamarla cuando se inyecte esta dependencia
    bsilvera -- 16-2020
     */
    public origen = 'Aplica_Credito';

    public URL_HUBSPOT = "https://tienda.jamar.com.pa/hubspot/api.php";

    public api_key =  "d47c29cfdf8e2456ac678c51f9e4ddfa8ec577f64e98aa9e863399f6a10210d4";

}
