<div class="componente_de_cliente pb-0 pt-0">

    <div class="container-fluid contenedor_cuotas_todas form-credijamar" *ngIf="vector_cliente_informacion['variable'] != 'ROJO' " >
        <!-- Titulo principal de la seccion de beneficios -->
        <div class="form-container pt-5 pb-3 pr-2 pl-2 bg-dark-light">
            <h2>{{ clientName }}, <span>¡Felicidades!</span> <br> Tu Crédito ha sido pre-aprobado</h2> 
        </div>

        
        <div class="contenedor-tarjeta-con-mensajes bg-dark-light">
            <div class="tarjeta-formato">
                <div class="title-bar bg-white">
                    <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/logo-credijamar.svg" alt="">
                    <div class="text d-flex justify-content-center">
                        <h6 class="mb-0">{{ vector_cliente_informacion['tipo_documento'] == "CC" ? "CC" : "PP" }} {{ vector_cliente_informacion['cedula'] }}</h6>
                        <p class="mb-0">{{ clientName }} {{ clientLastname }} </p>
                    </div>
                </div>
                <div class="content" style="background-image: url(https://pub-679e6e52a0524655b51d226e575658f6.r2.dev/background-tarjeta-credijamar-panama.svg);">
                    <p class="subtitle" *ngIf="clientesFuncionesService.informacion_de_visor.segmento!='ORO'">Cupo pre-aprobado</p>
                    <p class="subtitle" *ngIf="clientesFuncionesService.informacion_de_visor.segmento=='ORO'">Cupo Aprobado</p>
                    <p class="mensaje-text">${{clientesFuncionesService.informacion_de_visor.cupo | number:'1.2-2'}}</p>
                </div>
            </div>
        </div>
        <div class="contenedor-tarjeta-con-mensajes bg-dark-light pb-5">
            <div class="tarjeta-formato callout bg-white">
                    <img src="https://pub-679e6e52a0524655b51d226e575658f6.r2.dev/fi-rs-info.svg" alt="">
                    <p class="mensaje-text">Pronto nuestro asesor te contactará por WhatsApp o correo electrónico para completar tu solicitud.</p>
            </div>
        </div>

        <!-- Almacenamiento de los tipos de viabilidad -->
        <div class="row justify-content-md-center d-none">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 contenedor_cupo_pre_aprobado mb-3" *ngIf="clientesFuncionesService.informacion_de_visor.cupo > 0">
                <div class="card shadow">
                    <div class="corbatin" [ngClass]="clientesFuncionesService.informacion_de_visor.estrategia == 'AMARILLO' || clientesFuncionesService.informacion_de_visor.estrategia == 'AMARILLONARANJA' || clientesFuncionesService.informacion_de_visor.estrategia == ';AMARILLONARANJA' ? 'AMARILLO': clientesFuncionesService.informacion_de_visor.estrategia == 'NARANJA' ? 'NARANJA':'AZUL'"></div>
                    <div class="card-body">
                        <h5 class="card-title m-0" *ngIf="clientesFuncionesService.informacion_de_visor.segmento!='ORO'"  >Cupo pre-aprobado:</h5>
                        <h5 class="card-title m-0" *ngIf="clientesFuncionesService.informacion_de_visor.segmento=='ORO'"  >Cupo aprobado:</h5>
                        <p class="card-text">${{clientesFuncionesService.informacion_de_visor.cupo | number:'1.2-2'}}</p>
                    </div>
                </div>

            </div>
        </div>

        <!-- Seccion informativa del plan 24 meses -->
        <div class="container-informativo" *ngIf="vector_cliente_informacion['variable'] != 'ROJO' ">
            <div class="contenido w-100 d-flex">
                <img class="w-100 img-fluid" src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/plan-24-credijamar.svg" alt="">
                <!-- <div class="texto">
                <h3 class="pr-2">¡El preferido de nuestros clientes!</h3>
                <p class="pr-3">Plan 24, la elección más cómoda para tus compras a crédito.</p>
                </div> -->
            </div>
        </div>

        <!-- Seccion que renderiza los beneficios -->
        <div class="row justify-content-center p-0 m-0">
            <div class="col-12 p-0">
                <!-- titulo de la seccion de beneficios -->
                <div class="form-container mt-5 mb-3 p-0">
                    <h2>Estos son los <span>beneficios</span> <br> que obtienes con tu Credijamar</h2>
                    <div class="contenedor-imagen-beneficios">
                        <div class="imagenes-de-beneficio w-100">
                            <div class="beneficio">
                                <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/dollar-solid.svg" alt="">
                                <p>Cuotas bajas y fijas de principio a fin.</p>
                            </div>
                            <div class="beneficio green2">
                                <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/dollar-icon2.svg" alt="">
                                <p>Sin cuota inicial y por pago voluntario.</p>
                            </div>
                            <div class="beneficio green2">
                                <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/card-icon.svg" alt="">
                                <p>Tú eliges el plan: <br> 6,10,16,24 ó 40.</p>
                            </div>
                            <div class="beneficio">
                                <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/0-solid.svg" alt="">
                                <p>Sin cuota de manejo ni comisión de cierre.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Seccion que renderiza el carrusel -->
        <!-- <div class="row justify-content-center" *ngIf="products && products.length > 0" >         -->
        <div class="row justify-content-center">        
            <!-- Carrusel viejo -->
            <div class="col-12 d-none">            
                <p-carousel [value]="products" [numVisible]="3" [numScroll]="3" [circular]="true" [autoplayInterval]="4000" [responsiveOptions]="responsiveOptions">
                    <ng-template let-car pTemplate="item">
                        <div class="imgContainer">
                            <img class="imgCarusel shadow-sm" [src]="car.src" />
                        </div>
                        <div class="infromacion_delproducto ml-3">
                            <h5 class="color_707070 titulo font_size_15 font-weight-bold">{{car.name}}</h5>

                            <div class="precios" *ngIf="car.prices[agencyCode]" >
                                <div class="cuotas_mensuales line_height_1 mt-1"  *ngIf="car.prices[agencyCode].fee_tj_40">
                                    <p class="color_00ABB0 font_size_15 m-0">Cuanto semanal</p>
                                    <p class="color_707070 font-weight-bold font_size_30 mb-1">${{(car.prices[agencyCode].fee_tj_40)/4 | number:'1.2-2'}}</p>
                                </div>

                                <div class="precio line_height_1" *ngIf="car.prices[agencyCode].regular_price" >
                                    <p class="color_707070 font_size_15 m-0">Precio</p>
                                    <p class="color_707070 font_size_25">${{car.prices[agencyCode].regular_price | number:'1.2-2'}}</p>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>

            <!-- Titulo principal de la seccion de beneficios -->
            <div class="form-container mt-5 mb-3 d-none">
                <h2>Nuestros productos <br> <span>recomendados para ti.</span></h2> 
            </div>
            <!-- Carrusel de productos -->
            <div class="contenedor-carrusel d-none">
                <div class="carrusel-slide">
                    <a class="tarjeta-producto">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/ambient-photo.jpg" alt="">
                        <div class="variants">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Juego de Sala Lugo Lino Maloka Azul, Amarillo y Natural</h6>
                            <p class="prices"><span class="comparison-price">$3,000.00</span><span class="price">$3,500.00</span></p>
                            <p class="plans-text">Cuota semanal desde $36.600 <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/logo-credijamar.svg" alt=""></p>
                            <p class="includes">INCLUYE: SOFA 2PTOS + BUTACA + PUFF</p>
                            <span class="comprar">Comprar ahora</span>
                        </div>
                    </a>
                    <a class="tarjeta-producto">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/ambient-photo.jpg" alt="">
                        <div class="variants">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Juego de Sala Lugo Lino Maloka Azul, Amarillo y Natural</h6>
                            <p class="prices"><span class="comparison-price">$3,000.00</span><span class="price">$3,500.00</span></p>
                            <p class="plans-text">Cuota semanal desde $36.600 <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/logo-credijamar.svg" alt=""></p>
                            <p class="includes">INCLUYE: SOFA 2PTOS + BUTACA + PUFF</p>
                            <span class="comprar">Comprar ahora</span>
                        </div>
                    </a>
                    <a class="tarjeta-producto">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/ambient-photo.jpg" alt="">
                        <div class="variants">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Juego de Sala Lugo Lino Maloka Azul, Amarillo y Natural</h6>
                            <p class="prices"><span class="comparison-price">$3,000.00</span><span class="price">$3,500.00</span></p>
                            <p class="plans-text">Cuota semanal desde $36.600 <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/logo-credijamar.svg" alt=""></p>
                            <p class="includes">INCLUYE: SOFA 2PTOS + BUTACA + PUFF</p>
                            <span class="comprar">Comprar ahora</span>
                        </div>
                    </a>
                    <a class="tarjeta-producto">
                        <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/ambient-photo.jpg" alt="">
                        <div class="variants">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                            <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/variant.png" alt="">
                        </div>
                        <div class="product-data">
                            <h6>Juego de Sala Lugo Lino Maloka Azul, Amarillo y Natural</h6>
                            <p class="prices"><span class="comparison-price">$3,000.00</span><span class="price">$3,500.00</span></p>
                            <p class="plans-text">Cuota semanal desde $36.600 <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/logo-credijamar.svg" alt=""></p>
                            <p class="includes">INCLUYE: SOFA 2PTOS + BUTACA + PUFF</p>
                            <span class="comprar">Comprar ahora</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container-fluid contenedor_cuotas_todas form-credijamar"  > -->
    <div class="container-fluid contenedor_cuotas_todas form-credijamar" *ngIf="vector_cliente_informacion['variable'] == 'ROJO' " >
        <!-- Componente para la validación de los no viables -->
        <div class="contenedor-no-viable">
            <div class="contenido mt-3 bg-white">
                <h4 class="title bold mt-4">{{ clientName }}, ¡Gracias por aplicar a <br> CrediJamar!</h4>
                <p class="descripciones w-75">En esta ocasión tu nuevo mueble soñado lo podrás comprar pagando a contado.</p>
                <p class="descripciones w-75 mt-2"><b>¡Aprovecha el siguiente beneficio para ti!</b></p>
                <img src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/bono-no-viable.svg" alt="">
                <p class="descripciones w-75">Cuando estés en nuestra sucursal mas cercana, podremos asesorarte de como poder aplicar en Credijamar en un futuro próximo.</p>
                <a href="https://wa.link/apzksh" class="button mb-4">Redímelo en tu sucursal más cercana </a>
                <!-- <p class="descripciones">*Aplican <b><u>términos y condiciones</u></b></p> -->
            </div>
        </div>
    </div>
    <!-- <div class="container-fluid contenedor_cuotas_todas form-credijamar" *ngIf="vector_cliente_informacion['variable'] == 'ROJO' " > -->

    <div class="contenedor-viable mt-0 mb-0 d-none" *ngIf="vector_cliente_informacion['variable'] !== 'ROJO' ">
        <div class="contenido bg-white">
            <h4 class="title bold">¡Aprovecha este bono exclusivo para ti!</h4>
            <img src="https://pub-679e6e52a0524655b51d226e575658f6.r2.dev/bono-viable.svg" alt="">
            <p class="descripciones viable-terms w-75">Válido para compras a crédito en planes iguales o superiores a 16 meses.
                No aplica con otros bonos, ni planes de crédito a 6 o 10 meses..</p>
        </div>
    </div>

    <!-- Contenedor de imagenes informativas -->
    <div class="container-imagen-bloque w-100" *ngIf="vector_cliente_informacion['variable'] != 'ROJO' ">
        <a class="contenido w-100" href="https://cuenta.jamar.com.pa">
            <img class="w-100" src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/beneficio-final-screen.svg" alt="">
        </a>
        <a href="https://www.jamar.com.pa/pages/preguntas-frecuentes-sobre-pagos" class="contenido w-100">
            <img class="w-100" src="https://pub-a7294dba084e4b70bfc8e9503e6d63a4.r2.dev/beneficio-final-screen-2.svg" alt="">
        </a>
    </div>

</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="color_E84A4A font_size_26 font-weight-bold text-center">¡Gracias por preferirnos!</div>
        <div class="text-center my-4">
            <img src="/assets/images/llamada.svg" style="width: 120px;" alt="">
        </div>
        <div class="font_size_24 font-weight-bold color_707070 text-center mb-4">En minutos te llamaremos</div>
        <a href="https://www.jamar.com.pa/pages/preguntas-frecuentes-sobre-pagos" class="text-center">
            <button type="button" class="btn fondo_00ABB0 w-50 height_51 font-weight-bold font_size_15" data-dismiss="modal">OK ENTIENDO</button>
        </a>
      </div>
    </div>
  </div>
</div>
