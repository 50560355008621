import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Variables } from 'src/environments/variables';

const apiUrl = environment.apiUrl;
const apiUrl_appsprd = environment.apiUrl_appsprd;
const api_key_appsprd = environment.api_key_appsprd;

@Injectable({
  providedIn: 'root'
})

export class GetClientesService {

  constructor(private http: HttpClient,
              private variables:Variables) { }

  Consultar_Cliente(cedula:number, origen:string){

    // const endpoint1$ = this.http.get<any>(`${this.variables.ORDER_APP_ID}${apiUrl}/${this.variables.c_emp}/clients/${cedula}?user=TJWEB&program=${origen}`);
    // let bodyPost = {
    //   "company": "JP",
    //   "nit": cedula,
    //   "typeDoc": "CC"
    //   }
    // return endpoint1$;
    return this.http.get<any>(`${this.variables.ORDER_APP_ID}${apiUrl}/${this.variables.c_emp}/clients/${cedula}?user=TJWEB&program=${origen}`);
  }

  
  /*=======================================
  =              OCUPACIONES              =
  =======================================*/
  
  consultar_ocupaciones(){
    let criterios = JSON.stringify([{"variable":"c_emp","operador":"=","valor":"'"+this.variables.c_emp+"'"},{"variable":"est","operador":"=","valor":"'A'"}]);
    return this.http.get(`${apiUrl_appsprd}macocupaciones/findByCriteria?c_emp=${this.variables.c_emp}&api_key=${api_key_appsprd}&criterios=${criterios}`);
  }

  consultar_tiposocupacion(id_ocupacion){
    let criterios = JSON.stringify([{"variable":"c_emp","operador":"=","valor":"'"+this.variables.c_emp+"'"},{"variable":"cod_ocu","operador":"=","valor":"'"+id_ocupacion+"'"},{"variable":"estado","operador":"=","valor":"'A'"}]);
    return this.http.get(`${apiUrl_appsprd}tiposocupacion/findByCriteria?c_emp=${this.variables.c_emp}&api_key=${api_key_appsprd}&criterios=${criterios}`);
  }

  consultar_cargos(id_ocupacion){
    let criterios = JSON.stringify([{"variable":"c_emp","operador":"=","valor":"'"+this.variables.c_emp+"'"},{"variable":"cod_ocu","operador":"=","valor":"'"+id_ocupacion+"'"},{"variable":"est","operador":"=","valor":"'A'"}]);
    return this.http.get(`${apiUrl_appsprd}cargos/findByCriteria?c_emp=${this.variables.c_emp}&api_key=${api_key_appsprd}&criterios=${criterios}`);
  }
  
}
